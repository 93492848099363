import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};



export const createPortfolioPersonAttributeEnum = createAsyncThunk(
    'portfolioPersonAttributesEnum/createEnum',
    async (body: {  name: string,  portfolio_client_relationship_attribute: number}) => {
        const response = await APIClient.postData('/portfolio/relationship/client/enum/', body);
        return response.data;
    }
);

export const portfolioPersonAttributeEnumSlice = createSlice({
    name: 'portfolioPersonAttributeEnum',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(createPortfolioPersonAttributeEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPortfolioPersonAttributeEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createPortfolioPersonAttributeEnum.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
