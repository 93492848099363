import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPortfolioRelation } from '../interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioState {
    data: IPortfolioPortfolioRelation[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const fetchPortfolioPortfolioRelationships = createAsyncThunk(
    'PortfolioPortfolioRelationships/fetchPortfolioPortfolioRelationships',
    async () => {
        const response = await APIClient.getData(
            '/portfolio/relationship/type/portfolio/?limit=100000'
        );
        return response.data.results;
    }
);


export const portfolioPortfolioRelationshipsSlice = createSlice({
    name: 'portfolioPortfolioRelationships',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioPortfolioRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                fetchPortfolioPortfolioRelationships.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                fetchPortfolioPortfolioRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            )
        },
    });
    