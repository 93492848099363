import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';


enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPortfolioRelationshipAttributeState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPortfolioRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const getPortfolioPortfolioRelationshipEnum = createAsyncThunk(
    'singlePortfolioPortfolioRelationship/getPortfolioPortfolioRelationshipEnum',
    async (id: number) => {
        const response = await APIClient.getData(`/portfolio/relationship/portfolio/enum/attribute/${id}/`);
        return response.data.results;
    }
);



export const PortfolioPortfolioRelationshipEnumSlice = createSlice({
    name: 'singlePortfolioPortfolioRelationship',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPortfolioPortfolioRelationshipEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getPortfolioPortfolioRelationshipEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(getPortfolioPortfolioRelationshipEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});