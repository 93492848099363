import { Outlet } from 'react-router-dom';

import UnAuthenticatedLayout from '../../components/Layout/UnAuthenticated';

export default function Landing() {
    return (
        <UnAuthenticatedLayout>
            <Outlet />
        </UnAuthenticatedLayout>
    );
}
