import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';


enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioCompanyRelationshipAttributeState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioCompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const getPortfolioCompanyRelationshipEnum = createAsyncThunk(
    'singlePortfolioCompanyRelationship/getPortfolioCompanyRelationshipEnum',
    async (id: number) => {
        const response = await APIClient.getData(`/portfolio/relationship/company/enum/attribute/${id}/`);
        return response.data.results;
    }
);



export const PortfolioCompanyRelationshipEnumSlice = createSlice({
    name: 'singlePortfolioCompanyRelationship',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPortfolioCompanyRelationshipEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(getPortfolioCompanyRelationshipEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(getPortfolioCompanyRelationshipEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
