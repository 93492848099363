
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
import { IPersonAttribute } from '../persons-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonsAttributeState {
    data: IPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PersonsAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPersonsEnum = createAsyncThunk(
    'singlePersons/fetchPersonsEnum',
    async ({
        id,
    }: {
        id: string;
    }) => {
        const response = await APIClient.getData(`/client/enum/attribute/${id}/`);

        return response.data.results;
    }
);




export const personsEnumsSlice = createSlice({
    name: 'persons',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPersonsEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPersonsEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchPersonsEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
