/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import {  CreatePortfolioPortfolioAttribute } from '../../../../components/CreateAttribute/index';
import { createPortfolioPortfolioAttribute } from '../../../../modules/portfolio-portfolio-attributes/features/portfolioPortfolioAttributeSlice';

export default function CreatePortfolioAttribute({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    return (
        <SideCreate
            show={show}
            title={t('attributes.create.title')}
            toggle={toggle}
        >
            <CreatePortfolioPortfolioAttribute
                entity={4}
				toggle={toggle}
                create={(body) =>
                    dispatch(createPortfolioPortfolioAttribute(body)).unwrap()
                }
                show={show}
            />
        </SideCreate>
    );
}
