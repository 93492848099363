import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, Link } from 'react-router-dom';

export default function CompaniesAttributesEnum() {
    const { t } = useTranslation();
    const location = useLocation();
    return (
        <div className="flex flex-col justify-center text-sym-loader-1 text-left">
            <ul className="text my-4 flex">
                <li>
                    <Link to="/">Inicio</Link>
                </li>
                {location.pathname.includes('/company-enums') && (
                    <>
                        <li className="mx-2">{'>'}</li>
                        <li>{t('common.detail')}</li>
                    </>
                )}
            </ul>

            <Outlet />
        </div>
    );
}
