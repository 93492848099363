export default function SideCreate({
    children,
    title,
    show = false,
    toggle,
}: {
    children: React.ReactNode;
    title: string;
    show: boolean;
    toggle: () => void;
}) {
    return (
        <div
            className={`absolute z-20 top-0 right-0   w-full md:w-1/2 bg-white shadow-md rounded-md ${
                !show && 'hidden'
            }`}
        >
            <div className="flex space-x-4 h-screen">
                <button
                    type="button"
                    className="flex items-center bg-gray-200 hover:bg-gray-300  px-2 w-10"
                    onClick={toggle}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 5l7 7-7 7M5 5l7 7-7 7"
                        />
                    </svg>
                </button>
                <div className="relative w-full p-6 h-screen overflow-y-auto">
                    <button
                        type="button"
                        className="absolute top-2 right-2"
                        onClick={toggle}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                    <h3 className="text-xl font-bold mb-4">{title}</h3>
                    <hr className="mb-4" />
                    {children}
                </div>
            </div>
        </div>
    );
}
