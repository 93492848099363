import ActionButton, { IActionButtonProps } from './ActionButton';
import ActionLink, { IActionLinkProps } from './ActionLink';
import Logo from './Logo';

interface IMenuPhoneProps {
    title: string;
    logo?: string;
    menuItems?: (IActionLinkProps | IActionButtonProps)[];
    isMenuOpen: boolean;
    setIsMenuOpen: (isMenuOpen: boolean) => void;
    closeMenuText?: string;
    openMenuText?: string;
    className?: string;
}

export default function MenuPhone({
    title,
    logo,
    menuItems,
    isMenuOpen,
    setIsMenuOpen,
    closeMenuText,
    openMenuText,
    className,
}: IMenuPhoneProps) {
    return (
        <div className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden symlab-header-menu-mobile">
            <div
                className={`rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 ${className}`}
            >
                <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between symlab-header-menu-mobile__wrapper">
                        <Logo title={title} logo={logo} isMobile />
                        {isMenuOpen ? (
                            <div className="-mr-2 -my-2 symlab-header-menu-mobile__icon-open">
                                <button
                                    type="button"
                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    <span className="sr-only">
                                        {closeMenuText}
                                    </span>

                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        ) : (
                            <div className="-mr-2 -my-2 symlab-header-menu-mobile__icon-close">
                                <button
                                    type="button"
                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    aria-expanded="false"
                                    onClick={() => setIsMenuOpen(true)}
                                >
                                    <span className="sr-only">
                                        {openMenuText}
                                    </span>

                                    <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>

                    {isMenuOpen && (
                        <div className="py-6 px-5 space-y-6 symlab-header-menu-mobile__items">
                            <div>
                                {menuItems &&
                                    menuItems.length > 0 &&
                                    menuItems.map(
                                        (
                                            item:
                                                | IActionLinkProps
                                                | IActionButtonProps
                                        ) => {
                                            if ('path' in item) {
                                                return (
                                                    <ActionLink
                                                        key={item.label}
                                                        label={item.label}
                                                        icon={item.icon}
                                                        svgIcon={item.svgIcon}
                                                        path={item.path || ''}
                                                        showLabel={
                                                            item.showLabel
                                                        }
                                                        className={
                                                            item.className
                                                        }
                                                        iconPosition={
                                                            item.iconPosition
                                                        }
                                                    />
                                                );
                                            }
                                            if ('action' in item) {
                                                return (
                                                    <ActionButton
                                                        key={item.label}
                                                        label={item.label}
                                                        icon={item.icon}
                                                        svgIcon={item.svgIcon}
                                                        action={item.action}
                                                        showLabel={
                                                            item.showLabel
                                                        }
                                                        className={
                                                            item.className
                                                        }
                                                        iconPosition={
                                                            item.iconPosition
                                                        }
                                                    />
                                                );
                                            }
                                            return null;
                                        }
                                    )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

MenuPhone.defaultProps = {
    logo: '',
    menuItems: [],
    openMenuText: 'Open Menu',
    closeMenuText: 'Close Menu',
    className: '',
};
