
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface IPersonRelationshipAttribute{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any | null;
    status: Status;
    error: string | null;
}

const initialState: IPersonRelationshipAttribute = {
    data: null ,
    status: Status.idle,
    error: null,
};



export const createPortfolioPersonRelationshipAttributeValueEnum = createAsyncThunk(
    'singlePortfolioPersonRelationshipEnum/createPersonRelationshipEnum',
    // eslint-disable-next-line camelcase
    async ({name, portfolio_client_relationship_attribute}: {name: string, portfolio_client_relationship_attribute:number}) => {
        const response = await APIClient.postData(`/portfolio/relationship/client/enum/`, {
			name, 
			// eslint-disable-next-line camelcase
			portfolio_client_relationship_attribute, 

        });
        return response.data;
    }
);


export const updatePortfolioPersonRelationshipAttributeValueEnum = createAsyncThunk(
    'singlePortfolioPersonRelationshipEnum/updatePersonRelationshipEnum',
    async ({id,name}: {id: number, name: string}) => {
        const response = await APIClient.postData(`/portfolio/relationship/client/enum/${id}/`, {
            name

        });
        return response.data;
    }
);



export const portfolioPersonCRDRelationshipAttributeEnumSlice = createSlice({
    name: 'singlePortfolioPersonRelationshipEnum',
    initialState,
    reducers: {		
		resetEntityEnumPersonRelationship: (state) => {
		state.data = null;
		state.status = Status.idle;
		state.error =  null;
	},},
    extraReducers(builder) {
        builder
            .addCase(createPortfolioPersonRelationshipAttributeValueEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPortfolioPersonRelationshipAttributeValueEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createPortfolioPersonRelationshipAttributeValueEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePortfolioPersonRelationshipAttributeValueEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updatePortfolioPersonRelationshipAttributeValueEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(updatePortfolioPersonRelationshipAttributeValueEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});

export const { resetEntityEnumPersonRelationship } = portfolioPersonCRDRelationshipAttributeEnumSlice.actions;
