/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { RootState, useAppDispatch } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import LoadingAnimated from '../../../components/elements/Loading';

import {
    addClientAttribute,
    fetchSinglePerson,
} from '../../../modules/persons/features/singlePersonSlice';

import AttributesTable from './components/AttributesTable';
import RelationShipTable from './components/RelationShipTable';
import CompanyRelTable from './components/CompanyRelTable';
import PortfolioRelTable from './components/PortfolioRelTable';
import { Button } from '../../../components/elements/Buttons';
import Modal from '../../../components/Modal';
import { fetchPersonAttributes } from '../../../modules/persons-attributes/features/personsAttributeSlice';
// import { IPersonAttribute } from '../../../modules/persons-attributes/interfaces';
import { fetchPersonsEnum } from '../../../modules/persons-enums/personsEnum';
import { TypesAttribute } from '../../../components/enums/enums';
import { validateType, validateDate } from '../../../utils/helpers';
import UpdateEntity from './components/UpdateEntity';
import { getClient } from '../../../modules/persons/features/getPersonEntitySlice';
import DeleteEntity from './components/DeleteEntity';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value:any;
    name: string;
    index: number ;
};

export default function HomeTable() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
		watch,
        setValue,
    } = useForm<FormData>();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const params = useParams();
    const { id } = params;

    const singlePerson = useSelector((state: RootState) => state.singlePerson);

    const personsAttributes = useSelector(
        (state: RootState) => state.personsAttributes
    );

    const singlePersonsEnum = useSelector(
        (state: RootState) => state.personsEnums
    );
    const [name, setName] = useState<string | null>(null);

    const [showCreate, setShowCreate] = useState(false);
	const [showUpdate, setShowUpdate] = useState(false);
	const [showDelete, setShowDelete] = useState(false);

    // const [getAvailableAttributes, setGetAvailableAttributes] = useState(true);

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    // const [availableAttributes, setAvailableAttributes] = useState<
    //     IPersonAttribute[]
    // >([]);
    // const [newAttributeStatus, setNewAttributeStatus] = useState('idle');
    const [isEnum, setIsEnum] = useState(false);
    const [isUnique, setIsUnique] = useState(false);
    const [idAttribute, setIdAttribute] = useState('');

    useEffect(() => {
        if (singlePerson.status === 'idle' || id !== singlePerson.id) {
            dispatch(fetchSinglePerson(id ?? ''));
			dispatch(getClient(Number(id)))
        }
    }, [singlePerson.status, dispatch, id, singlePerson.id]);



    useEffect(() => {
        if (personsAttributes.status === 'idle') {
            dispatch(fetchPersonAttributes());
        }
    }, [dispatch, personsAttributes.status, id]);



    useEffect(() => {
        setName('');

        if (singlePerson.status === 'success') {
            const personName =
                singlePerson.attributes.find(
                    (a) => a.attribute.label === 'NOMBRE_COMPLETO'
                )?.data ?? '';

            setName(personName);
        }
    }, [singlePerson.status, singlePerson.attributes, name]);
	
	const [type, setType] = useState(0);

	const value = watch('value');
	const [msgError, setMsgError] = useState('')



    const onSubmit = async (data: FormData) => {
        try {
            
            if(data.value === 'N/A' ){
				

				// if(singlePersonsEnum.data && singlePersonsEnum.data[0].enum.length > 0){

				// 	const enumNA = singlePersonsEnum.data[0].enum.filter((e: { name: string; }) => e.name.toUpperCase() === 'N/A')

				// 	if(enumNA.length > 0){
				// 		// eslint-disable-next-line no-param-reassign
				// 		data.index = data.index || 1;

				// 		setAddRequestStatus('loading');
				// 		await dispatch(
				// 			addClientAttribute({
				// 				id: id ?? '',
				// 				attributes: [data],
				// 			})
				// 		);

				// 		// await create(body);
				// 		setAddRequestStatus('success');
				// 		reset();
				// 		setShowCreate(false);
				// 	} else{
						
				// 		await dispatch(
				// 			createPersonAttributeEnum({
				// 				name: data.value,
				// 				attribute: Number(idAttribute),
				// 				// client_relationship_attribute: 0,
				// 			})
				// 		);

				// 		setValue('index', 1);
				// 		setAddRequestStatus('loading');

				// 		await dispatch(
				// 			addClientAttribute({
				// 				id: id ?? '',
				// 				attributes: [data],
				// 			})
				// 		);

				// 		setAddRequestStatus('success');
				// 		reset();
				// 		setShowCreate(false);					
				// 	}
				// } else {
				// 	await dispatch(
				// 		createPersonAttributeEnum({
				// 			name: data.value,
				// 			attribute: Number(idAttribute),
				// 			// client_relationship_attribute: 0,
				// 		})
				// 	);
				// 	setValue('index', 1);
				// 	setAddRequestStatus('loading');
				// 	await dispatch(
				// 		addClientAttribute({
				// 			id: id ?? '',
				// 			attributes: [data],
				// 		})
				// 	);
				// 	// dispatch(fetchSinglePerson(id ?? ''));
				// 	// await create(body);
				// 	setAddRequestStatus('success');
				// 	reset();
				// 	setShowCreate(false);	
				// }
                // await dispatch(createPersonAttributeEnum({"name": data.value, "attribute": Number(idAttribute)}))
                // setValue("index", 1)
				// // eslint-disable-next-line no-param-reassign
				// // data.index = 1
                // setAddRequestStatus('loading');
                // await dispatch(
                //     addClientAttribute({
                //         id: id ?? '',
                //         attributes: [data],
                //     })
                // ).unwrap();
                // await dispatch(fetchSinglePerson(id ?? ''));
                // // await create(body);
                // setAddRequestStatus('success');
                // reset();
                // setShowCreate(false);
            }
            else{
				if(type === TypesAttribute.Number){			
					// eslint-disable-next-line no-param-reassign
					data.value = Number(data.value)
				}
				if(type === TypesAttribute.Date ){
					let splitDate = ''
					if(value){
						splitDate = value.split('-')
					}
			
					const isValidDate = validateDate(splitDate, value)
					
					if(isValidDate){
						data.value = isValidDate
					}else{
						setMsgError('Formato fecha invalido debe ingresar dd-mm-yyyy')
					}

				
				}
				// eslint-disable-next-line no-param-reassign
				data.index = data.index || 1;
                setAddRequestStatus('loading');
                await dispatch(
                    addClientAttribute({
                        id: id ?? '',
                        attributes: [data],
                    })
                ).unwrap();
                // await create(body);
                setAddRequestStatus('success');
                reset();
                setShowCreate(false);
            }
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };


	useEffect(() => {
	  if(addRequestStatus === 'success'){
		setAddRequestStatus('idle')  
		dispatch(fetchSinglePerson(id ?? ''))
		dispatch(getClient(Number(id)))
	  }
	

	}, [addRequestStatus, dispatch, id])
	
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('value', '');
        setIsEnum(false);
        setIsUnique(false)

		if(e.target.selectedOptions[0].getAttribute('data-type') === "3"){
			setType(3)
		}
		if(e.target.selectedOptions[0].getAttribute('data-type') === "4"){
			setType(4)
		}
        if (e.target.selectedOptions[0].getAttribute('data-unique') === 'true') {
            setIsUnique(true)
        }

        if (e.target.selectedOptions[0].getAttribute('data-enum') === 'true') {
            setIsEnum(true);

            setIdAttribute(
                e.target.selectedOptions[0].getAttribute(
                    'data-id'
                ) as SetStateAction<string>
            );
        }

    };

    useEffect(() => {
        if (isEnum) {

            dispatch(fetchPersonsEnum({ id: idAttribute }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnum, idAttribute]);


    return (
        <div className="relative mb-20">

            <PageHeader title={name ?? t('persons.title')} />

            {singlePerson.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {singlePerson.status === 'success' && (
				
                <>
			<div className="grid justify-items-stretch mb-12">
                <div className=" flex justify-self-end">
				<button
                        onClick={() => setShowDelete(true)}
                        type="button"
                        className="w-full ml-2 flex items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-rose-700	 hover:bg-rose-800	focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        Eliminar Entidad
                    </button>
                    <button
                        onClick={() => setShowUpdate(true)}
                        type="button"
                        className="w-full ml-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                    >
                        Actualizar Entidad
                    </button>
                </div>
            </div>

                    <div>
                        <Button
                            label={t('attributes.create.addNew')}
                            type="button"
                            onClick={() => setShowCreate(!showCreate)}
                        />
                    </div>
                    <AttributesTable
                        clientId={id || ''}
                        data={singlePerson.attributes}
                    />
                    <RelationShipTable
                        data={singlePerson.personRelations}
                        clientId={id ? parseInt(id, 10) : 0}
                    />
                    <CompanyRelTable
                        data={singlePerson.companyRelations}
                        clientId={id ? parseInt(id, 10) : 0}
                    />
                    <PortfolioRelTable
                        data={singlePerson.portfolioRelations}
                        clientId={id ? parseInt(id, 10) : 0}
                    />
                    {showCreate && (
                        <Modal
                            setShowModal={setShowCreate}
                            onClose={() => setShowCreate(false)}
                            className="h-[500px]"
                        >
                            <div className="px-4">
                                <h3 className="text-center text-2xl font-bold mb-10">
                                    {t('attributes.create.addNew')}
                                </h3>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {/* START Attributes Selection */}
                                        <div className="w-full mt-4 mb-4">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {t('attributes.title')} *
                                                <div className="mt-1">
                                                    <select
                                                        id="name"
                                                        className={`${
                                                            errors.name
                                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                : ''
                                                        }`}
                                                        {...register('name', {
                                                            required: true,
                                                            onChange:
                                                                handleChange,
                                                        })}
                                                    >
                                                        <option value="">
                                                            {t(
                                                                'attributes.create.selectAnAttribute'
                                                            )}
                                                        </option>
                                                        {personsAttributes.status ===
                                                            'success' &&
                                                            personsAttributes.data.length >
                                                                0 &&
																personsAttributes.data.map(
                                                                (attribute) => (
                                                                    <option
                                                                        key={
                                                                            attribute.id
                                                                        }
                                                                        value={
                                                                            attribute.name
                                                                        }
                                                                        data-enum={
                                                                            attribute.type ===
                                                                            2
                                                                                ? 'true'
                                                                                : 'false'
                                                                        }
                                                                        data-type={
																			attribute.type
																		}
                                                                        data-unique={
                                                                            attribute.is_unique
                                                                                ? 'true'
                                                                                : 'false'
                                                                        }
                                                                        data-id={
                                                                            attribute.id
                                                                        }
                                                                    >
                                                                        {
                                                                            attribute.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </select>
                                                </div>
                                            </label>
                                            {errors.name && (
                                                <p className="text-red-600 ml-2">
                                                    {t(
                                                        'common.errors.fieldRequired'
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                        {/* END Attributes Selection */}

                                        {isEnum ? (
                                            <div className="w-full mt-4 mb-4">
                                                <label
                                                    htmlFor="value"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Valores de atributo *
                                                    <div className="mt-1">
                                                        <select
                                                            id="value"
                                                            className={`${
                                                                errors.value
                                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                    : ''
                                                            }`}
                                                            {...register(
                                                                'value',
                                                                {
                                                                    required:
                                                                        true,
                                                                }
                                                            )}
                                                        >
                                                            <option value="">
                                                                {t(
                                                                    'attributes.create.selectAnAttribute'
                                                                )}
                                                            </option>
                                                            {singlePersonsEnum.status ===
                                                                'success' &&
                                                            singlePersonsEnum
                                                                .data.length >
                                                                0 && (
                                                                singlePersonsEnum.data.map(
                                                                    (
                                                                        attribute
                                                                    ) =>
                                                                    attribute.enum.length > 0 
                                                                        ?
                                                                        attribute.enum.map(
                                                                            (
                                                                                e
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        e.id
                                                                                    }
                                                                                    value={
                                                                                        e.name
                                                                                    }
                                                                                    data-enum={
                                                                                        attribute.type ===
                                                                                        2
                                                                                            ? 'true'
                                                                                            : 'false'
                                                                                    }
                                                                                    data-unique ={
                                                                                        attribute.is_unique
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                    }
                                                                                    data-id={
                                                                                        e.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        e.name
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )
                                                                        :
                                                                        (<option key={attribute.id} value="N/A">
                                                                        No existen valores
                                                                        </option>)
                                                            
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </label>

                                                {errors.name && (
                                                    <p className="text-red-600 ml-2">
                                                        {t(
                                                            'common.errors.fieldRequired'
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <label
                                                    htmlFor="value"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {t('common.value')} *
                                                    <div className="mt-1">
                                                        <input
                                                            id="value"
                                                            type={validateType(type)}
                                                            className={`${
                                                                errors.value
                                                                    ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                                    : ''
                                                            }`}
                                                            {...register(
                                                                'value',
                                                                {
                                                                    required:
                                                                        true,
                                                                }
                                                            )}
                                                        />
                                                        {errors.value && (
                                                            <p className="text-red-600 ml-2">
                                                                {errors.value
                                                                    .type ===
                                                                    'required' &&
                                                                    t(
                                                                        'common.errors.fieldRequired'
                                                                    )}
                                                            </p>
                                                        )}
														{
															msgError !== '' && 
																<p className="text-red-600 ml-2">
																	{msgError}
																</p>
															
														}
                                                    </div>
                                                </label>
                                            </div>
                                        )}
                                        {/*  */}

                                        {/* {isEnum === true && isUnique === false && (
                                            <div className="mt-3">
                                                <label
                                                    htmlFor="index"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {' '}
                                                    Indice
                                                    <div className="mt-1">
                                                        <input
                                                            {...register(
                                                                'index',
                                                                {}
                                                            )}
                                                            type="number"
                                                            min={1}
                                                            defaultValue={1}
                                                            id="index"
                                                            className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        )} */}
										{
											isUnique === false && 
											<div className="mt-3">
											<label
												htmlFor="index"
												className="block text-sm font-medium text-gray-700"
											>
												{' '}
												Indice
												<div className="mt-1">
													<input
														{...register(
															'index',
															{}
														)}
														type="number"
														min={1}
														defaultValue={1}
														id="index"
														className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
													/>
												</div>
											</label>
										</div>
										}
                                        <div className="mt-4">
                                            {addRequestStatus === 'error' && (
                                                <p className="text-red-600 mb-2">
                                                    {t(
                                                        'attributes.create.error'
                                                    )}
                                                </p>
                                            )}
                                            {addRequestStatus === 'success' && (
                                                <p className="text-green-600 mb-2">
                                                    {t(
                                                        'attributes.create.success'
                                                    )}
                                                </p>
                                            )}
                                            <button
                                                disabled={
                                                    addRequestStatus ===
                                                    'loading'
                                                }
                                                type="submit"
                                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                            >
                                                {addRequestStatus === 'loading'
                                                    ? t('wait.message')
                                                    : t('common.save')}
                                            </button>
                                            <Button
                                                type="button"
                                                variant="link"
                                                className="w-full mt-10"
                                                label={t('common.cancel')}
                                                onClick={() =>
                                                    setShowCreate(false)
                                                }
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    )}
                </>
            )}

				{
				showUpdate &&
				<UpdateEntity  setShowUpdate={setShowUpdate} />
			}
				{
				showDelete &&
				<DeleteEntity  setShowDelete={setShowDelete} />
			}
        </div>
    );
}
