/* eslint-disable @typescript-eslint/no-unused-vars */
import { TFunction, useTranslation } from 'react-i18next';
// import { UserRoles } from '../modules/users/enums';
// import { IItems } from '../components/Navigation/SideBar/Items';

const persons = (t: TFunction<'translation', undefined>) => ({
    label: t('persons.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
        </svg>
    ),
    route: '/',
    activeRoute: 'persons',
});

const business = (t: TFunction<'translation', undefined>) => ({
    label: t('business.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
        </svg>
    ),
    route: '/company',
});

const users = (t: TFunction<'translation', undefined>) => ({
    label: t('investment.title'),
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
        </svg>
    ),
    route: '/portfolio',
});
const personsAttributes = (t: TFunction<'translation', undefined>) => ({
    label: t('persons-attributes.title'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    //         />
    //     </svg>
    // ),
    route: '/persons-attributes',
});

const businessAttributes = (t: TFunction<'translation', undefined>) => ({
    label: t('business-attributes.title'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/business-attributes',
});

const portfolioAttributes = (t: TFunction<'translation', undefined>) => ({
    label: t('investment-attributes.title'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/portfolio-attributes',
});
const personRelationshipAttributes = (
    t: TFunction<'translation', undefined>
) => ({
    // label: 'Relación persona',
    label: t('person-person-relationship.attributeRelationship'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/relationship-attributes',
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const portfolioClientRelationshipAttributes = (
    t: TFunction<'translation', undefined>
) => ({
    // label: 'Relación persona',
    label: 'Relación C-Natural',

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/portfolios-persons-attributes',
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const portfolioCompanyRelationshipAttributes = (
    t: TFunction<'translation', undefined>
) => ({
    // label: 'Relación persona',
    label: 'Relación C-Jurídica',

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/portfolios-companies-attributes',
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const portfolioPortfolioRelationshipAttributes = (
    t: TFunction<'translation', undefined>
) => ({
    // label: 'Relación persona',
    label: 'Relación C-Portfolio',

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/portfolios-portfolios-attributes',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const companyRelationshipAttributes = (
    t: TFunction<'translation', undefined>
) => ({
    // label: 'Relación persona',
    label: 'Relacion Jurídica',
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/company-relationship-attributes',
});

const personPersonRelationships = (t: TFunction<'translation', undefined>) => ({
    label: t('person-person-relationship.title'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    //         />
    //     </svg>
    // ),
    route: '/person-person-relationships',
});

const personBusinessRelationships = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('person-business-relationship.title'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/person-business-relationships',
});

const personPortfolioRelationships = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('person-investment-relationship.title'),
    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/person-investment-relationships',
});

const businessPortfolioRelationships = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('business-investment-relationship.title'),

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/business-investment-relationships',
});


// ###### ENUMS

const personsEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('persons-attributes-enum.title'),

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/person-enums',
});

const companiesEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('business-attributes-enum.title'),

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/company-enums',
});


const portfoliosEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('portfolio-attributes-enum.title'),


    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),



    route: '/portfolio-enums',

});

const personRelationshipEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('person-relationship-attributes-enum.title'),


    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),



    route: '/person-relationship-enums',

});

const companyRelationshipEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('company-relationship-attributes-enum.title'),


    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),



    route: '/company-relationship-enums',

});

const portfolioPersonRelationshipEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('portfolio-person-relationship-attributes-enum.title'),


    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),



    route: '/portfolio-person-relationship-enums',

});

const portfolioCompanyRelationshipEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('portfolio-company-relationship-attributes-enum.title'),


    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),



    route: '/portfolio-company-relationship-enums',

});

const portfolioPortfolioRelationshipEnums = (
    t: TFunction<'translation', undefined>
) => ({
    label: t('portfolio-portfolio-relationship-attributes-enum.title'),


    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),



    route: '/portfolio-portfolio-relationship-enums',

});

const portfoliosPortfolioRelationships = () => ({
    label: 'Cuentas - Cuentas',

    // icon: (
    //     <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-6 w-6"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="currentColor"
    //     >
    //         <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             strokeWidth={2}
    //             d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //         />
    //     </svg>
    // ),
    route: '/portfolios-investment-relationships',
});

const relationships = (t: TFunction<'translation', undefined>) => ({
    label: t('common.relationships'),
    noLink: true,
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
        </svg>
    ),
    route: '',
    subItems: [
        personPersonRelationships(t),
        personBusinessRelationships(t),
        personPortfolioRelationships(t),
        businessPortfolioRelationships(t),
        portfoliosPortfolioRelationships(),
    ],
});

const attributes = (t: TFunction<'translation', undefined>) => ({
    label: t('common.attributes'),
    noLink: true,
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
        </svg>
    ),
    route: '',
    subItems: [
        personsAttributes(t),
        businessAttributes(t),
        portfolioAttributes(t),
        personRelationshipAttributes(t),
        companyRelationshipAttributes(t),
        portfolioClientRelationshipAttributes(t),
        portfolioCompanyRelationshipAttributes(t),
        portfolioPortfolioRelationshipAttributes(t),
    ],
});
const enums = (t: TFunction<'translation', undefined>) => ({
    label: 'Valores Coleccion',
    noLink: true,
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
            />
        </svg>

    ),
    route: '',
    subItems: [
        personsEnums(t),
        companiesEnums(t),
        portfoliosEnums(t),
        personRelationshipEnums(t),
        companyRelationshipEnums(t),
        portfolioPersonRelationshipEnums(t),
        portfolioCompanyRelationshipEnums(t),
        portfolioPortfolioRelationshipEnums(t),
        // businessAttributes(t),
        // portfolioAttributes(t),
        // personRelationshipAttributes(t),
        // companyRelationshipAttributes(t),
        // portfolioClientRelationshipAttributes(t),
        // portfolioCompanyRelationshipAttributes(t),
        // portfolioPortfolioRelationshipAttributes(t),
    ],
});

function MenuItems() {
    const { t } = useTranslation();
    return [
        persons(t),
        business(t),
        users(t),
        attributes(t),
        enums(t),
        relationships(t),
    ];
    // switch (role) {
    //     case UserRoles.ADMIN:
    //         return [persons(t), providers(t), users(t)];
    //     case UserRoles.PROVIDER:
    //         return [persons(t)];
    //     case UserRoles.USER:
    //         return [];
    //     default:
    //         return [];
    // }
}

export default MenuItems;
