
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
import { IPersonAttributeEnum } from '../persons-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonsAttributeState {
    data: IPersonAttributeEnum | null;
    status: Status;
    error: string | null;
}

const initialState: PersonsAttributeState = {
    data: null ,
    status: Status.idle,
    error: null,
};



export const createPersonsEnum = createAsyncThunk(
    'singlePersons/createPersonsEnum',
    async ({name, attribute}: {name: string, attribute:number}) => {
        const response = await APIClient.postData(`/client/enum/`, {
			name, 
			attribute, 

        });
        return response.data;
    }
);


export const updatePersonsEnum = createAsyncThunk(
    'singlePersons/updatePersonsEnum',
    async ({id,name}: {id: number, name: string}) => {
        const response = await APIClient.postData(`/client/enum/${id}/`, {
            name

        });
        return response.data;
    }
);



export const personEnumSlice = createSlice({
    name: 'personsEnum',
    initialState,
    reducers: {		
		resetEntityEnum: (state) => {
		state.data = null;
		state.status = Status.idle;
		state.error =  null;
	},},
    extraReducers(builder) {
        builder
            .addCase(createPersonsEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPersonsEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createPersonsEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePersonsEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updatePersonsEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(updatePersonsEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});

export const { resetEntityEnum } = personEnumSlice.actions;
