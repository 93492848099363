/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Row, useAsyncDebounce } from 'react-table';

// Define a default UI for filtering
export default function TableSearch({
    title,
    placeholder,
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    className,
}: {
    title?: string;
    placeholder?: string;
    preGlobalFilteredRows: Row<any>[];
    globalFilter: any;
    setGlobalFilter: (filterValue: any) => void;
    className?: string;
}) {
    const count = preGlobalFilteredRows?.length;
    const [value, setValue] = useState(globalFilter);

    const onChange = useAsyncDebounce((searchValue) => {
        setGlobalFilter(searchValue || undefined);
    }, 200);

    return (
        <div className={`relative ${className}`}>
            <label htmlFor="table-search">
                <span className="ml-2 sr-only">{title}</span>
                <input
                    id="table-search"
                    className="mt-2"
                    type="text"
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={placeholder}
                />
            </label>
            <div className="absolute right-2 bottom-0">
                <p>{count} registros</p>
            </div>
        </div>
    );
}

TableSearch.defaultProps = {
    title: 'Search',
    placeholder: 'Search...',
    className: '',
};
