
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
// import { IPersonAttributeEnum } from '../persons-attributes/interfaces/index';
import { IBusinessAttribute } from '../business-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BusinessEnumAttributeState {
    data: IBusinessAttribute[];
    status: Status;
    error: string | null;
}

const initialState: BusinessEnumAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchAllBusinessEnum = createAsyncThunk(
    'singleBusinessEnums/fetchAllBusinesssEnum',
    async () => {
        const response = await APIClient.getData(`/company/enum/?limit=100000`);

        return response.data.results;
    }
);



export const fetchAllBusinessEnumSlice = createSlice({
    name: 'busienessEnums',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchAllBusinessEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchAllBusinessEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchAllBusinessEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
