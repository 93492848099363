
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonRelationshipAttributeState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    status: Status;
    error: string | null;
}

const initialState: PersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPersonRelationshipEnum = createAsyncThunk(
    'singleRelationship/fetchPersonRelationshipEnum',
    async (id: number) => {
        const response = await APIClient.getData(`/client/relationship/enum/attribute/${id}/`);
        return response.data.results;
    }
);



export const personRelationshipEnumSlice = createSlice({
    name: 'personRelationshipEnums',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPersonRelationshipEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPersonRelationshipEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(fetchPersonRelationshipEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
