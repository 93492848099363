
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
// import { IPersonAttribute } from '../persons-attributes/interfaces/index';
import { IPortfolioAttribute } from '../portfolios-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfoliosAttributeState {
    data: IPortfolioAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfoliosAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfoliosEnum = createAsyncThunk(
    'singlePortfolios/fetchPortfoliosEnum',
    async ({
        id,
    }: {
        id: string;
    }) => {
        const response = await APIClient.getData(`/portfolio/enum/attribute/${id}/`);

        return response.data.results;
    }
);



export const portfoliosEnumsSlice = createSlice({
    name: 'persons',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfoliosEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfoliosEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchPortfoliosEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
