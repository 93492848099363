/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal';
import { RootState } from '../../../../store';
import {
    resetSingleEntityPerson,
} from '../../../../modules/persons/features/personEntitySlice';

import LoadingAnimated from '../../../../components/elements/Loading/index';
import { updatePortfolio } from '../../../../modules/portfolios/features/portfolioEntity';
import { fetchBusiness } from '../../../../modules/business/features/businessSlice';
import { fetchPersons } from '../../../../modules/persons/features/personsSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reference_id: string;
    client: string | number | null; 
    company: string | number | null; 
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function UpdateEntity({
    setShowUpdate,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowUpdate: any;
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const portfolio = useSelector((state: RootState) => state.portfolio);
 
    const persons = useSelector((state: RootState) => state.persons);
    const business = useSelector((state: RootState) => state.business);
    

    const updatePortf = useSelector((state: RootState) => state.portfolioEntity);

    const { id } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>();



	useEffect(() => {
	  if(persons.status === 'idle' && business.status === 'idle' ){
		dispatch(fetchBusiness())
		dispatch(fetchPersons())
	  }
	}, [business.status, dispatch, persons.status])

    useEffect(() => {
        if (portfolio.status === 'success') {

            if (portfolio.data) {
                if (portfolio.data.company) {
					setValue('company', portfolio.data.company);
				}
                if (portfolio.data.client) {
					setValue('client', portfolio.data.client);
                }
				setValue('reference_id', portfolio.data.reference_id);
            }
        }
    }, [dispatch, portfolio.data, portfolio.status, setValue]);

    const onSubmit = (data: FormData) => {
        const payload = {
            id: Number(id),
			client: data.client === 'null' ?  null : data.client,
			company: data.company === 'null' ?  null : data.company,
            reference_id: data.reference_id,
        };
        dispatch(updatePortfolio(payload));
    };
    const [message, setMessage] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');

    useEffect(() => {
        if (updatePortf.error) {
            setMessage('Error al actualizar entidad');
            dispatch(resetSingleEntityPerson());
        }
    }, [dispatch, updatePortf.error]);

    useEffect(() => {
        if (!updatePortf.error && updatePortf.status === 'success') {
            setMessage('');
            setMessageSuccess('Entidad Actualizado');
            dispatch(resetSingleEntityPerson());
        }
    }, [dispatch, updatePortf.error, updatePortf.status]);

    return (
        <>
            {portfolio.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {portfolio.status === 'success' && (
                <Modal
                    setShowModal={setShowUpdate}
                    onClose={() => setShowUpdate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            Actualizar Portfolio
                        </h3>
                        <div>
                            {/* onSubmit={}handleSubmit(onSubmit) */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Reference *
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('reference_id', {
                                                    required: true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {errors.reference_id && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
								<div className="w-full mt-4 mb-4">
												<label
													htmlFor="name"
													className="block text-sm font-medium text-gray-700"
												>
													Cliente
													<div className="mt-1">
														<select
														{...register('client', {
														})}
														>
															<option value="null">Seleccione cliente</option>
															{
																persons &&
																persons.data.map((p) => (
																	<option  key={p.id} value={p.id}>{p.name}</option>
																))
															}
														</select>
			
													</div>
												</label>
												{errors.client && (
													<p className="text-red-600 ml-2">
														{t(
															'common.errors.fieldRequired'
														)}
													</p>
												)}
											</div>
											<div className="w-full mt-4 mb-4">
												<label
													htmlFor="name"
													className="block text-sm font-medium text-gray-700"
												>
													Juridica
													<div className="mt-1">
														<select
														{...register('company', {
															required: true
														})}
														>
															<option value="null">Seleccione Juridíca</option>
															{
																business &&
																business.data.map((b) => (
																	<option key={b.id} value={b.id}>{b.name}</option>
																))
															}
														</select>
			
													</div>
												</label>
												{errors.company && (
													<p className="text-red-600 ml-2">
														{t(
															'common.errors.fieldRequired'
														)}
													</p>
												)}
											</div>
											
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                >
                                    {updatePortf.status === 'loading'
                                        ? t('wait.message')
                                        : t('common.update')}
                                </button>
                            </form>
                        </div>
                        {message !== '' && (
                            <p className="text-red-600 text-center mt-2">
                                {message}
                            </p>
                        )}
                        {messageSuccess !== '' && (
                            <p className="text-green-600 text-center mt-2">
                                {messageSuccess}
                            </p>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
}
