/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Column, useTable, useSortBy, usePagination } from 'react-table';

import { Button } from '../../../../components/elements/Buttons';
import EditableCell from '../../../../components/Table/EditableCell';
import {
    IItemAttribute,
    IAttribute,
} from '../../../../modules/common/interfaces';
import { updateClientAttributes } from '../../../../modules/persons/features/singlePersonSlice';
import { useAppDispatch } from '../../../../store';
// import { validateDate } from '../../../../utils/helpers';
import { TypesAttribute } from '../../../../components/enums/enums';
import { validateDate, validateTypeAttribute } from '../../../../utils/helpers';

type FormData = {
    attributes: {
        name: string;
        value: string | number;
		index: number;
    }[];
};

export default function AttributesTable({
    clientId,
    data,
}: {
    clientId: string;
    data: IItemAttribute[];
}) {
    const dispatch = useAppDispatch();
    const { register, handleSubmit, setValue, watch, reset } =
        useForm<FormData>({
            defaultValues: {
                attributes: [],
            },
        });
    const [attributesState, setAttributesState] = useState('idle');

    const attributes = watch('attributes');
	

    useEffect(() => {
        register('attributes');
    }, [register]);
    const columns = useMemo<Column<IItemAttribute>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.attribute').toString(),
                Cell: (row: {
                    row: {
                        original: {
                            attribute: { name: { toString: () => string } };
                        };
                    };
                }) => row.row.original.attribute.name.toString(),
            },

            {
                Header: t('common.value').toString(),
                accessor: 'data',
                Cell: EditableCell,
				
            },
            {
                Header: 'Indice',
                Cell: (row: {
                    row: {
                        original: {
                            attribute: {
                                is_unique: { toString: () => string };
                            };
                            index: {
                                index: { toString: () => string };
                            };
                        };
                    };
                }) =>
                    row.row.original.attribute.is_unique
                        ? '-'
                        : row.row.original.index,
            },

            {
                Header: 'Tipo',
                Cell: (row: {
                    row: {
                        original: {
                            attribute: { type: { toString: () => string } };
                        };
                    };
                }) =>

				validateTypeAttribute(row.row.original.attribute.type)
						 
            },
            {
                Header: 'Multi-Atributo',
                accessor: 'attribute',
                Cell: ({ cell: { value } }: { cell: { value: IAttribute } }) =>
                    value.is_unique ? 'No' : 'Si',
            },
            {
                Header: 'Padre',
                accessor: 'parent_attribute_id',
                Cell: (row: {
                    row: {
                        original: {
                            attribute: {
                                parent_attribute_id: { toString: () => string };
                                parent: {
                                    fields: {
                                        name: { toString: () => string };
                                    };
                                };
                            };
                        };
                    };
                }) =>
                    row.row.original.attribute.parent_attribute_id &&
                    row.row.original.attribute.parent
                        ? `${row.row.original.attribute.parent_attribute_id}-${row.row.original.attribute.parent.fields.name}`
                        : '-',
            },  
        ],

        []
    );

	const [msgError, setMsgError] = useState('')
    const updateMyData = (
        rowIndex: string | number,
        columnId: number,
        original: IItemAttribute,
        value: string,
    ) => {

        if (original.data === value) {
			    return;
		}
			
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const upData: any = {
            name: original.attribute.name,
            value,
			index: original.index
        };

		
		if(original.attribute.type === TypesAttribute.Date){
			const splitDate = value.split('-')
			const isValidDate = validateDate(splitDate, value)
			
			if(isValidDate){
				setAttributesState('')
				upData.value = isValidDate
			}else{
				setAttributesState('error')
				setMsgError('Formato fecha invalido debe ingresar dd-mm-yyyy')
			}
		
		}	
	
		if(original.attribute.type === TypesAttribute.Number){			
			upData.value = Number(value)
		}
			
	
		

        const exist = attributes.findIndex((item) => item.name === upData.name);

        if (exist !== -1) {
            attributes[exist] = upData;
            setValue('attributes', [...attributes]);
            return;
        }

        setValue('attributes', [...attributes, upData]);
    };

    const tableInstance = useTable(
        {
            columns,
            data: data || [],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
            updateMyData,
        },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;

    const update = async (formData: FormData) => {
        try {
			if(attributesState !== 'error'){

				setAttributesState('loading');
				await dispatch(
					updateClientAttributes({
						id: clientId,
						attributes: formData.attributes,
					})
				).unwrap();
				setAttributesState('success');
				setValue('attributes', []);
				reset();
			}
			
        } catch (e) {
            setAttributesState('error');
        }
    };

    return (
        <div className="relative mb-20">
            <div className="absolute flex-col text-right -top-20 right-0">
                <div className="mb-2 h-8">
                    <p className="text-green-600">
                        {attributesState === 'success'
                            ? t('attributes.saved')
                            : ''}
                    </p>
					{attributesState === 'error' &&
						<p className="text-red-600">
							{msgError}
						</p>
					
					}
                </div>
                <Button
                    disabled={
                        attributes.length === 0 || attributesState === 'loading'
                    }
                    onClick={handleSubmit(update)}
                    label={
                        attributesState === 'loading'
                            ? t('wait.message')
                            : t('common.save')
                    }
                />
            </div>

            {data && data.length === 0 && (
                <h3 className="text-lg text-center">No existen atributos</h3>
            )}

            {data && data.length > 0 && (
                <>
                    {pageOptions.length > 1 && (
                        <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="overflow-x-auto shadow-md">
                        <table
                            className="table-auto w-full mx-auto"
                            {...getTableProps()}
                        >
                            <thead className="text-white">
                                {
                                    // Loop over the header rows

                                    headerGroups.map((headerGroup) => (
                                        // Apply the header row props

                                        <tr
                                            className="text-left"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {
                                                // Loop over the headers in each row

                                                headerGroup.headers.map(
                                                    (column) => (
                                                        // Apply the header cell props

                                                        <th
                                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                minWidth: '2%',
                                                            }}
                                                        >
                                                            <div className="flex justify-between">
                                                                {
                                                                    // Render the header

                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                }
                                                                {column.canSort ? (
                                                                    <span
                                                                        className={`${
                                                                            column.isSorted &&
                                                                            'text-sym-primary-500'
                                                                        }`}
                                                                    >
                                                                        {column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M19 9l-7 7-7-7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                        {!column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M5 15l7-7 7 7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                    )
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    // Loop over the table rows

                                    page.map((row) => {
                                        // Prepare the row for display

                                        prepareRow(row);

                                        return (
                                            // Apply the row props

                                            <tr
                                                className="odd:bg-slate-200 hover:bg-slate-300"
                                                {...row.getRowProps()}
                                                // onClick={() => {
                                                //     navigate(
                                                //         `/persons/${row.original.id}`
                                                //     );
                                                // }}
                                            >
                                                {
                                                    // Loop over the rows cells

                                                    row.cells.map((cell) => (
                                                        // Apply the cell props

                                                        <td
                                                            className="py-4 px-2 cursor-pointer"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents

                                                                cell.render(
                                                                    'Cell'
                                                                )
                                                            }
															
                                                        </td>
                                                    ))
                                                }
												
                                            </tr>
											
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {pageOptions.length > 1 && (
                        <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
