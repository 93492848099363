/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IEntity, IItemAttribute } from '../../common/interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface SliceState {
    id: string | null;
    attributes: IItemAttribute[];
    personRelations: IEntity[];
    companyRelations: IEntity[];
    portfolioRelations: IEntity[];
    status: Status;
    error: string | null;
}

const initialState: SliceState = {
    id: null,
    attributes: [],
    personRelations: [],
    companyRelations: [],
    portfolioRelations: [],
    status: Status.idle,
    error: null,
};

export const updateClientAttributes = createAsyncThunk(
    'singlePerson/updateClientAttributes',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string | number, index:number }[];
    }) => {
        const response = await APIClient.postData(`/client/id/${id}/detail/`, {
            data: attributes,
        });
        return response.data;
    }
);

export const addClientAttribute = createAsyncThunk(
    'singlePerson/addClientAttribute',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string, index: number}[];
    }) => {
        const neWData: any = []
        
        attributes.forEach(element => {
            neWData.push(            {
                name: element.name,
                value: element.value,
                index: Number(element.index )
            })

        });
        const response = await APIClient.postData(`/client/id/${id}/detail/`, {
            data: neWData,
        });

        return response.data;
    }
);

export const addClientPersonRelation = createAsyncThunk(
    'singlePerson/addClientPersonRelation',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { client_from: number; client_to: number; relationship_name: string  };
    }) => {
        const response = await APIClient.postData(`/client/id/${id}/relationship/`, attributes);

        return response.data;
    }
);

export const addClientBusinessRelation = createAsyncThunk(
    'singlePerson/addClientBusinessRelation',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { client_from: number; company_to: number; relationship_name: string  };
    }) => {
        const response = await APIClient.postData(`/company/id/${id}/relationship/`, attributes);
        return response.data;
    }
);

export const addClientPortfolioRelation = createAsyncThunk(
    'singlePerson/addClientPortfolioRelation',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { client_from: number; portfolio_to: number; relationship_name: string  };
    }) => {
        const response = await APIClient.postData(`/portfolio/id/${id}/relationship/client/`, attributes);
        return response.data;
    }
);

export const fetchSinglePerson = createAsyncThunk(
    'singlePerson/fetchSinglePerson',
    async (id: string) => {
        const client = await APIClient.getData(
            `/client/id/${id}/detail?limit=10000`
        );

        const relationsTypeResult = await APIClient.getData(
            `/client/relationship/?limit=10000`
        );
        const relationsType = relationsTypeResult.data.results;

        const clientsResult = await APIClient.getData(`/client/?limit=10000`);

        const clients = clientsResult.data.results;

        const relationsResult = await APIClient.getData(
            `/client/id/${id}/relationship/?limit=10000`
        );
        const relationsData = relationsResult.data.results
            .map(
                (r: {
					id: number;
                    type: {
                        id: number
                    };
					idRelationship:number;
                    client_from: number;
                    client_to: number;
                }) => {

                    const rela = relationsType.find(
                        (rt: { id: number }) => rt.id === r.type.id
                    );

                    const from = clients.find(
                        (c: any) => c.id === r.client_from
                    );

                    const to = clients.find((c: any) => c.id === r.client_to);

                    if (from && to) {
                        return {
                            id: from.id.toString() === id ? to.id : from.id,
							idRelationship: r.id,
							relationshipTypeId: r.type.id,
                            entity:
                                from.id.toString() === id
                                    ? `${to.name}`
                                    : `${from.name}`,
                            name: rela.name,
                        };
                    }
                    return undefined;
                }
            )
            .filter((r: any) => r !== undefined);

        const companiesResult = await APIClient.getData(
            `/client/id/${id}/company/relationship/?limit=10000`
        );
        const companies = companiesResult.data.results;

        const cRelationsTypeResult = await APIClient.getData(
            `/company/relationship/?limit=10000`
        );
        const cRelationsType = cRelationsTypeResult.data.results;

        const cRelationsData = companies
            .map((r: any) => {
                const rela = cRelationsType.find((rt: any) => rt.id === r.type);
                if (r && rela) {
                    return {
                        id: r.company_to.id,
                        entity: r.company_to.name,
						relationshipTypeId: r.type,
						idRelationship: r.id,

                        name: rela.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        const portfoliosResult = await APIClient.getData(
            `/portfolio/?limit=10000`
        );
        const portfolios = portfoliosResult.data.results;

        const portfolioClientResult = await APIClient.getData(
            `/client/id/${id}/portfolio/relationship/?limit=10000`
        );
        const portfolioClient = portfolioClientResult.data.results;

        const pRelationsData = portfolioClient
            .map((r: any) => {
                const por = portfolios.find(
                    (rt: any) => rt.id === r.portfolio_to
                );
                if (por && r) {
                    return {
                        id: por.id,
                        entity: por.reference_id,
						idRelationship: r.id,
						relationshipTypeId: r.type.id,	
                        name: r.type.name,
                    };
                }
                return undefined;
            })
            .filter((r: any) => r !== undefined);

        return {
            id,
            client: client.data.results,
            relations: relationsData,
            companies: cRelationsData,
            portfolios: pRelationsData,
        };
    }
);

export const singlePersonSlice = createSlice({
    name: 'singlePerson',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchSinglePerson.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchSinglePerson.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.id = action.payload.id;
                state.attributes = action.payload.client;
                state.personRelations = action.payload.relations;
                state.companyRelations = action.payload.companies;
                state.portfolioRelations = action.payload.portfolios;
            })
            .addCase(fetchSinglePerson.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(updateClientAttributes.fulfilled, (state) => {
                state.status = Status.success;
                // Add any fetched data to the array
            })
            .addCase(updateClientAttributes.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addClientAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            }).addCase(addClientPersonRelation.pending, (state) => {
                state.status = Status.loading;
                // Add any fetched data to the array
            })
            .addCase(addClientPersonRelation.fulfilled, (state) => {
                state.status = Status.success;                
            })
            .addCase(addClientPersonRelation.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addClientBusinessRelation.pending, (state) => {
                state.status = Status.loading;
                // Add any fetched data to the array
            })
            .addCase(addClientBusinessRelation.fulfilled, (state) => {
                state.status = Status.success;                
            })
            .addCase(addClientBusinessRelation.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addClientPortfolioRelation.pending, (state) => {
                state.status = Status.loading;
                // Add any fetched data to the array
            })
            .addCase(addClientPortfolioRelation.fulfilled, (state) => {
                state.status = Status.success;                
            })
            .addCase(addClientPortfolioRelation.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
