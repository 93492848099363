


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonRelationshipAttributeState {
    data: IPersonRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const fetchPersonRelationshipRelAttributesValues = createAsyncThunk(
    'personAttributes/fetchPersonAttributesRelValues',
    async (id: string) => {
        const response = await APIClient.getData(
            `/client/relationship/attribute/relationship/${id}?limit=100000`
        );
        return response.data.results;
    }
);


export const personsRelationshipAttributesRelValuesSlice = createSlice({
    name: 'personRelationshipAttributesValues',
    initialState,
    reducers: {

		resetValues: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(fetchPersonRelationshipRelAttributesValues.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPersonRelationshipRelAttributesValues.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload
            })
            .addCase(fetchPersonRelationshipRelAttributesValues.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
          
    },
});

export const { resetValues } = personsRelationshipAttributesRelValuesSlice.actions;
