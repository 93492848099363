import { useState } from 'react';

import ActionItems from './ActionItems';
import MenuPhone from './MenuPhone';
import { IActionButtonProps } from './ActionButton';
import { IActionLinkProps } from './ActionLink';
import Logo from './Logo';

interface IHeaderProps {
    title: string;
    logo?: string;
    openMenuText?: string;
    closeMenuText?: string;
    menuItems?: (IActionLinkProps | IActionButtonProps)[];
    menuItemsPhone?: (IActionLinkProps | IActionButtonProps)[];
    className?: string;
    classNameMobile?: string;
}

export default function Header({
    title,
    logo = '',
    openMenuText,
    closeMenuText,
    menuItems,
    menuItemsPhone,
    className,
    classNameMobile,
}: IHeaderProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (
        <div
            className={`symlab-header relative z-50 bg-sym-bg-blue text-white py-2 rounded-tr-md shadow-md shadow-slate-400 ${className}`}
        >
            <div className="symlab-header__wrapper mx-auto px-0 md:px-6">
                <div className="justify-between items-center h-16 md:justify-start md:space-x-10 hidden md:flex">
                    <Logo title={title} logo={logo} />
                    <ActionItems menuItems={menuItems} />
                </div>
            </div>

            <MenuPhone
                title={title}
                logo={logo}
                className={classNameMobile}
                menuItems={
                    menuItemsPhone && menuItemsPhone.length > 0
                        ? menuItemsPhone
                        : menuItems
                }
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                openMenuText={openMenuText}
                closeMenuText={closeMenuText}
            />
        </div>
    );
}

Header.defaultProps = {
    logo: '',
    openMenuText: 'Open menu',
    closeMenuText: 'Close menu',
    menuItems: [],
    menuItemsPhone: [],
    className: '',
    classNameMobile: '',
};
