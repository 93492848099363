
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
import { IPortfolioAttributeEnum } from '../portfolios-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface IPortfoliosAttributeEnum{
    data: IPortfolioAttributeEnum | null;
    status: Status;
    error: string | null;
}

const initialState: IPortfoliosAttributeEnum = {
    data: null ,
    status: Status.idle,
    error: null,
};



export const createPortfoliosEnum = createAsyncThunk(
    'singlePortfolioEnum/createPortfolioEnum',
    async ({name, attribute}: {name: string, attribute:number}) => {
        const response = await APIClient.postData(`/portfolio/enum/`, {
			name, 
			attribute, 

        });
        return response.data;
    }
);


export const updatePortfolioEnum = createAsyncThunk(
    'singlePortfolioEnum/updatePortfolioEnum',
    async ({id,name}: {id: number, name: string}) => {
        const response = await APIClient.postData(`/portfolio/enum/${id}/`, {
            name

        });
        return response.data;
    }
);



export const PortfolioEnumSlice = createSlice({
    name: 'singlePortfolioEnum',
    initialState,
    reducers: {		
		resetEntityEnumPortfolio: (state) => {
		state.data = null;
		state.status = Status.idle;
		state.error =  null;
	},},
    extraReducers(builder) {
        builder
            .addCase(createPortfoliosEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPortfoliosEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createPortfoliosEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(updatePortfolioEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updatePortfolioEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(updatePortfolioEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});

export const { resetEntityEnumPortfolio } = PortfolioEnumSlice.actions;
