
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
import { IBusinessAttributeEnum } from '../business/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface IBusinessAttribute{
    data: IBusinessAttributeEnum | null;
    status: Status;
    error: string | null;
}

const initialState: IBusinessAttribute = {
    data: null ,
    status: Status.idle,
    error: null,
};



export const createBusinessEnum = createAsyncThunk(
    'singleBusinessEnum/createBusinessEnum',
    async ({name, attribute}: {name: string, attribute:number}) => {
        const response = await APIClient.postData(`/company/enum/`, {
			name, 
			attribute, 

        });
        return response.data;
    }
);


export const updateBusinessEnum = createAsyncThunk(
    'singleBusinessEnum/updateBusinessEnum',
    async ({id,name}: {id: number, name: string}) => {
        const response = await APIClient.postData(`/company/enum/${id}/`, {
            name

        });
        return response.data;
    }
);



export const businessEnumSlice = createSlice({
    name: 'singleBusinessEnum',
    initialState,
    reducers: {		
		resetEntityEnumCompany: (state) => {
		state.data = null;
		state.status = Status.idle;
		state.error =  null;
	},},
    extraReducers(builder) {
        builder
            .addCase(createBusinessEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createBusinessEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(createBusinessEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(updateBusinessEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(updateBusinessEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(updateBusinessEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});

export const { resetEntityEnumCompany } = businessEnumSlice.actions;
