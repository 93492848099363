import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBusiness } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BusinessState {
    data: IBusiness[];
    status: Status;
    error: string | null;
}

const initialState: BusinessState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBusiness = createAsyncThunk(
    'business/fetchBusiness',
    async () => {
        const response = await APIClient.getData('/company/?limit=100000');
        return response.data.results;
    }
);



export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {		
			resetBusines: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},},
    extraReducers(builder) {
        builder
            .addCase(fetchBusiness.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchBusiness.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchBusiness.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
export const { resetBusines } = businessSlice.actions;
