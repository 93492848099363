import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICompanyRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyRelationshipAttributeState {
    data: ICompanyRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: CompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const createCompanyRelationshipAttribute = createAsyncThunk(
    'companyRelationshipAttributes/create',
    async (body: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
        company_relationship_type: number;
    }) => {
        const response = await APIClient.postData(
            '/company/relationship/attribute/',
            body
        );
        return response.data;
    }
);
export const createCompanyRelationshipAttributeEnum = createAsyncThunk(
    'company/relationshipAttributes/createEnum',
    async (body: {
        name: string;
        // attribute: number;
        company_relationship_attribute: number;
    }) => {
        const response = await APIClient.postData(
            '/company/relationship/enum/',
            body
        );
        return response.data;
    }
);

export const addCompanyRelationshiptAttribute = createAsyncThunk(
    'singleCompany/addCompanyRelationshiptAttribute',
    async ({
        id,
        attributes,
    }: {
        id: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes: { name: string; value: any; index: number, idAttribute: number }[];
    }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const neWData: any = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let response: any;

        attributes.forEach(async (element) => {
            neWData.push({
                name: element.name,
                value: element.value,
                index: Number(element.index),
				idAttribute: Number(element.idAttribute)

            });

			response = await APIClient.postData(
				`/company/relationship/id/${id}/detail/${element.idAttribute}/`,
				{
					data: [{
						name: element.name,
						value: element.value,
						index: Number(element.index),
						// idAttribute: Number(element.idAttribute)
					}],				}
			);
        });

        return response?.data;
    }
);
export const companyRelationshipAttributesSlice = createSlice({
    name: 'companyRelationshipAttributes',
    initialState,
    reducers: {
		resetValuesCompany: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder.addCase(createCompanyRelationshipAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createCompanyRelationshipAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                createCompanyRelationshipAttribute.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(addCompanyRelationshiptAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addCompanyRelationshiptAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createCompanyRelationshipAttributeEnum.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                createCompanyRelationshipAttributeEnum.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});
export const { resetValuesCompany } = companyRelationshipAttributesSlice.actions;
