

export interface ValidAttributes{
	id: number;
	name:string
}
export const validTypeAttributes: ValidAttributes[] = [
	{"id": 1, "name": "Libre"},
	{"id": 2, "name": "Enumeración"},
	{"id": 3, "name": "Fecha"},
	{"id": 4, "name": "Númerico"},
]