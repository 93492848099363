import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IItemAttributePortfolioPortfolioRelationship } from '../../common/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPortfolioRelationshipAttributeState {
    data: IItemAttributePortfolioPortfolioRelationship[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPortfolioRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolioPortfolioRelationshipAttributesValues = createAsyncThunk(
    'portfolioPPRPortfoliosRelationshipAttributesValues/fetchPortfolioPortfolioAttributesValues',
    async (id: string) => {
        const response = await APIClient.getData(
            `/portfolio/relationship/portfolio/id/${id}/detail/${id}?limit=100000`
        );
        return response.data.results;
    }
);
export const portfolioPortfoliosRelationshipAttributesValuesSlice = createSlice({
    name: 'portfolioPPRPortfoliosRelationshipAttributesValues',
    initialState,
    reducers: {

		resetValuesPortPortRel: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolioPortfolioRelationshipAttributesValues.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolioPortfolioRelationshipAttributesValues.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchPortfolioPortfolioRelationshipAttributesValues.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });

    },
}); 

export const { resetValuesPortPortRel } = portfolioPortfoliosRelationshipAttributesValuesSlice.actions;
