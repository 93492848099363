import ActionButton, { IActionButtonProps } from './ActionButton';
import ActionLink, { IActionLinkProps } from './ActionLink';

interface IActionItemsProps {
    menuItems?: (IActionLinkProps | IActionButtonProps)[];
}

export default function ActionItems({ menuItems }: IActionItemsProps) {
    return (
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-4 symlab-header-items">
            {menuItems &&
                menuItems.length > 0 &&
                menuItems.map((item: IActionLinkProps | IActionButtonProps) => {
                    if ('path' in item) {
                        return (
                            <ActionLink
                                key={item.label}
                                label={item.label}
                                icon={item.icon}
                                svgIcon={item.svgIcon}
                                path={item.path || ''}
                                showLabel={item.showLabel}
                                className={item.className}
                                iconPosition={item.iconPosition}
                            />
                        );
                    }
                    if ('action' in item) {
                        return (
                            <ActionButton
                                key={item.label}
                                label={item.label}
                                icon={item.icon}
                                svgIcon={item.svgIcon}
                                action={item.action}
                                showLabel={item.showLabel}
                                className={item.className}
                                iconPosition={item.iconPosition}
                            />
                        );
                    }
                    return null;
                })}
        </div>
    );
}

ActionItems.defaultProps = {
    menuItems: [],
};
