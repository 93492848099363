/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import useAuth from '../../../context/useAuth';

type FormData = {
    email: string;
    code: string;
    password: string;
};

export default function ForgotPassword() {
    const [searchParams] = useSearchParams();
    const { setForgotPassword, loading, error } = useAuth();
    console.log({ error });
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormData>({
        defaultValues: {
            email: searchParams.get('email') || '',
        },
    });

    const [forgotState, setForgotState] = useState<{
        loading: boolean;
        error: {
            code?: string;
            message?: string;
        } | null;
        success: boolean;
    }>({
        loading: false,
        error: null,
        success: false,
    });

    const [showPassword, setShowPassword] = useState(false);

    console.log({ error });

    const onSubmit = (data: FormData) => {
        setForgotState({ ...forgotState, loading: true });
        setForgotPassword(data.email, data.code, data.password);
        setForgotState({ ...forgotState, loading: false, success: true });
        if (!error) {
            reset();
        }
    };

    const handleSetPasswordError = (code: string) => {
        if (code === 'ExpiredCodeException') {
            return t('forgotPassword.errors.codeExpired');
        }

        if (code === 'CodeMismatchException') {
            return t('forgotPassword.errors.codeMismatch');
        }

        console.log('Unknown error', code);
        return t('signIn.errors.UnknownError');
    };

    return (
        <div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto"
                    src="/images/logo.svg"
                    alt="MBI Logo"
                    width="300"
                />
                <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-300">
                    {t('forgotPassword.title')}
                </h1>
                <p className="mt-2 text-center text-sm text-white max-w">
                    {t('forgotPassword.backToSignIn')}
                    <Link
                        to="/"
                        className="font-medium text-sym-primary-600 hover:text-sym-primary-500 focus:outline-none focus:ring-2 focus:ring-sym-primary-500 ml-2"
                    >
                        {t('signIn.title')}
                    </Link>
                </p>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
                    <p className="text-slate-600 mb-6">
                        {t('forgotPassword.description')}
                    </p>
                    <div className="text-slate-600 mb-6 font-bold">
                        <Link to="/forgot">
                            {t('forgotPassword.dontHaveCode')}
                        </Link>
                    </div>

                    <form
                        className="mb-0 space-y-6"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.email')}
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        type="email"
                                        className={`${
                                            errors.email
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="email"
                                        {...register('email', {
                                            required: true,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:
                                                    'Invalid email address',
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <p className="text-red-600 ml-2">
                                            {errors.email.type === 'required' &&
                                                t(
                                                    'forgotPassword.errors.emailRequired'
                                                )}
                                            {errors.email.type === 'pattern' &&
                                                t(
                                                    'forgotPassword.errors.emailRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div>
                            <label
                                htmlFor="code"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('forgotPassword.code')}
                                <div className="mt-1">
                                    <input
                                        id="code"
                                        type="text"
                                        className={`${
                                            errors.code
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('code', {
                                            minLength: 6,
                                            required: true,
                                        })}
                                    />
                                    {errors.code && (
                                        <p className="text-red-600 ml-2">
                                            {errors.code.type === 'required' &&
                                                t(
                                                    'forgotPassword.errors.codeRequired'
                                                )}
                                            {errors.code.type === 'minLength' &&
                                                t(
                                                    'forgotPassword.errors.codeMin',
                                                    { count: 6 }
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                        <div className="relative">
                            <button
                                className="absolute right-0 top-0 text-sm text-slate-500"
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword
                                    ? t('form.hidePassword')
                                    : t('form.showPassword')}
                            </button>
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.password')}
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        className={`${
                                            errors.password
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('password', {
                                            minLength: 8,
                                            required: true,
                                        })}
                                    />
                                    {errors.password && (
                                        <p className="text-red-600 ml-2">
                                            {errors.password.type ===
                                                'required' &&
                                                t(
                                                    'forgotPassword.errors.passwordRequired'
                                                )}
                                            {errors.password.type ===
                                                'minLength' &&
                                                t(
                                                    'forgotPassword.errors.passwordMin',
                                                    { count: 8 }
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div>
                            {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSetPasswordError(error)}
                                </p>
                            )}
                            {forgotState.success && (
                                <p className="text-green-700 font-bold mb-2 p-2">
                                    {t('forgotPassword.passwordChanged')}
                                </p>
                            )}

                            <button
                                disabled={forgotState.loading || loading}
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-600 hover:bg-sym-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                {loading
                                    ? t('wait.message')
                                    : t('forgotPassword.change')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
