/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import CreateAttribute from '../../../../components/CreateAttribute';

import { createBusinessAttribute } from '../../../../modules/business-attributes/features/businessAttributesSlice';

export default function CreatePortfolioAttribute({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    return (
        <SideCreate
            show={show}
            title={t('attributes.create.title')}
            toggle={toggle}
        >
            <CreateAttribute
                entity={2}
                create={(body) =>
                    dispatch(createBusinessAttribute(body)).unwrap()
                }
                show={show}
            />
        </SideCreate>
    );
}
