/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';
import RoleCheck from '../../../components/RoleCheck';
import { UserRoles } from '../../../modules/users/enums';
import useAuth from '../../../context/useAuth';
import { IBusiness } from '../../../modules/business/interfaces';
import { fetchBusiness, resetBusines } from '../../../modules/business/features/businessSlice';
import TableSearch from '../../../components/Table/TableSearch';
import Modal from '../../../components/Modal';
import { createBusiness, resetEntityBusines } from '../../../modules/business/features/businessEntity';
import { validaRut } from '../../../utils/helpers';


type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: string;
	rut: string;
};


export default function ListBusiness() {
    const { user } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const business = useSelector((state: RootState) => state.business);
    const navigate = useNavigate();
    const [showCreate, setShowCreate] = useState(false);
    const entityBusiness = useSelector((state: RootState) => state.businessEntity);

    // let locale = 'en-US';
    // if (i18n.language === 'es') {
    //     locale = 'es-ES';
    // }
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();
	

    useEffect(() => {
        if (business.status === 'idle') {
            dispatch(fetchBusiness());
        }
    }, [business.status, dispatch]);

    const columns = useMemo<Column<IBusiness>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('users.firstName').toString(),
                accessor: 'name',
            },
            {
                Header: t('common.rut').toString(),
                accessor: 'rut',
                Cell: ({ cell: { value } }: { cell: { value: string } }) =>
                    format(value),
            },
        ],

        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: business.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

	const [message, setMessage] = useState('')
	const [messageSuccess, setMessageSuccess] = useState('')
	const onSubmit = (data: FormData) => {
	let existsRut = false;
	business.data.forEach(entity => {
		if(entity.rut === data.rut){
			existsRut = true;
		}
	});
	if(existsRut){
		setMessage(`Entidad con Rut ${data.rut} ya existe`);
		return
	}
		const payload = {
			name: data.name,
			rut: data.rut,
		}
		dispatch(createBusiness(payload))
	}

	
	useEffect(() => {
	  if( entityBusiness.error  ){
		  dispatch(resetEntityBusines())

		}
	}, [dispatch, entityBusiness.error])

	useEffect(() => {
	  if( !entityBusiness.error && entityBusiness.status === 'success'){
			setMessage('')
			setMessageSuccess('Entidad creada')	
			dispatch(resetBusines())
			dispatch(fetchBusiness());
		}
	}, [dispatch, entityBusiness.error, entityBusiness.status])






    return (


		<div className="relative mb-20">
			<div className='grid justify-items-stretch'
			>
				<div className='justify-self-end'> 
				
				<button
					onClick={() => setShowCreate(true)}
					type="button"
					className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
				>
					Crear Entidad

					</button>		

				</div>
		</div>
            <RoleCheck
                allowedRoles={[UserRoles.ADMIN, UserRoles.SHOPPING_LEADER]}
                currentRole={user?.role || 'USER'}
            >
                {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={t('bids.create.buttonTitle')}
                    type="button"
                    onClick={() => setShowCreate(!showCreate)}
                />
            </RoleCheck>
            <PageHeader title={t('business.title')} />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {business.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {business.status === 'success' && business.data.length === 0 && (
                <div className="text-center text-gray-500">
                    <h2 className="text-2xl">{t('common.listEmpty')}</h2>
                    <RoleCheck
                        allowedRoles={[
                            UserRoles.ADMIN,
                            UserRoles.SHOPPING_LEADER,
                        ]}
                        currentRole={user?.role || 'USER'}
                    >
                        <Button
                            className="mt-6"
                            label={t('business.create.buttonTitle')}
                            type="button"
                            onClick={() => setShowCreate(!showCreate)}
                        />
                    </RoleCheck>
                </div>
            )}

            {business.status === 'success' && business.data.length > 0 && (
                <>
                    <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                        <div className="flex space-x-4 items-center">
                            <button
                                type="button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Ir la página:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const newPage = e?.target.value
                                            ? Number(e?.target.value) - 1
                                            : 0;
                                        gotoPage(newPage);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e?.target.value));
                                }}
                            >
                                {[50, 100, 200, 300].map((pageS) => (
                                    <option key={pageS} value={pageS}>
                                        Mostrar {pageS}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="overflow-x-auto shadow-md">
                        <table
                            className="table-auto w-full mx-auto"
                            {...getTableProps()}
                        >
                            <thead className="text-white">
                                {
                                    // Loop over the header rows

                                    headerGroups.map((headerGroup) => (
                                        // Apply the header row props

                                        <tr
                                            className="text-left"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {
                                                // Loop over the headers in each row

                                                headerGroup.headers.map(
                                                    (column) => (
                                                        // Apply the header cell props

                                                        <th
                                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                minWidth: '2%',
                                                            }}
                                                        >
                                                            <div className="flex justify-between">
                                                                {
                                                                    // Render the header

                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                }
                                                                {column.canSort ? (
                                                                    <span
                                                                        className={`${
                                                                            column.isSorted &&
                                                                            'text-sym-primary-500'
                                                                        }`}
                                                                    >
                                                                        {column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M19 9l-7 7-7-7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                        {!column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M5 15l7-7 7 7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                    )
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    // Loop over the table rows

                                    page.map((row) => {
                                        // Prepare the row for display

                                        prepareRow(row);

                                        return (
                                            // Apply the row props

                                            <tr
                                                className="odd:bg-slate-200 hover:bg-slate-300"
                                                {...row.getRowProps()}
                                                onClick={() => {
                                                    navigate(
                                                        `/company/${row.original.id}`
                                                    );
                                                }}
                                            >
                                                {
                                                    // Loop over the rows cells

                                                    row.cells.map((cell) => (
                                                        // Apply the cell props

                                                        <td
                                                            className="py-4 px-2 cursor-pointer"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents

                                                                cell.render(
                                                                    'Cell'
                                                                )
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                        <div className="flex space-x-4 items-center">
                            <button
                                type="button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Ir la página:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const newPage = e?.target.value
                                            ? Number(e?.target.value) - 1
                                            : 0;
                                        gotoPage(newPage);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e?.target.value));
                                }}
                            >
                                {[50, 100, 200, 300].map((pageS) => (
                                    <option key={pageS} value={pageS}>
                                        Mostrar {pageS}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            )}

{
				showCreate && 
				<Modal
					setShowModal={setShowCreate}
					onClose={() => setShowCreate(false)}
					className="h-[500px]"
				>
								<div className="px-4">
									<h3 className="text-center text-2xl font-bold mb-10">
										Creación persona jurídica
									</h3>
									<div>
										{/* onSubmit={}handleSubmit(onSubmit) */}
										<form onSubmit={handleSubmit(onSubmit)} >
											{/* START Attributes Selection */}
											<div className="w-full mt-4 mb-4">
												<label
													htmlFor="name"
													className="block text-sm font-medium text-gray-700"
												>
													Nombre *
													<div className="mt-1">
														<input type='text' 
															{...register('name', {
																required: true
															})}
														/>
													</div>
												</label>
												{errors.name && (
													<p className="text-red-600 ml-2">
														{t(
															'common.errors.fieldRequired'
														)}
													</p>
												)}
											</div>
								
											<div className="w-full mt-4 mb-4">
												<label
													htmlFor="name"
													className="block text-sm font-medium text-gray-700"
												>
													Rut *
													<div className="mt-1">
														<input type='text' 
															{...register('rut', {
																required: true,
																validate : (e ) => validaRut(e) === true
															
													
															})}
														/>
													</div>
												</label>
												{errors.rut && errors.rut?.type !== 'validate'  &&(
													<p className="text-red-600 ml-2">
														{t(
															'common.errors.fieldRequired'
														)}
													</p>
													
												)}
												{errors.rut && (
													
														errors.rut?.type === 'validate' &&
														(
															<p className="text-red-600 ml-2">
															Formato incorrecto debe ser XXXXXXXX-X
														</p>
														)
													
													
												)}
											</div>
											
											<button
			
												type="submit"
												className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
											>
												
												{entityBusiness.status === 'loading'
													? t('wait.message')
													: t('common.create')}
											</button>		
										</form>
									</div>
									{
										message !== '' && 
										<p className="text-red-600 text-center mt-2">{message}</p>
									}
									{
										messageSuccess !== '' && 
										<p className="text-green-600 text-center mt-2">{messageSuccess}</p>
									}
								</div>
				</Modal>
			}
        </div>

		
    );
}
