/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { API, Auth } from 'aws-amplify';

const apiName = process.env.REACT_APP_API_NAME ?? '';
export default class APIClient {
    public static async postData(path: string, body: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };

        return axios.post(
            `${process.env.REACT_APP_API_URL}${path}`,
            body,
            myInit
        );
    }

    public static async putData(path: string, body?: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };
        return API.put(`${process.env.REACT_APP_API_URL}`, `${path}`, myInit);
    }

    public static async putDataEntity(path: string, body?: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };
		return axios.put(
            `${process.env.REACT_APP_API_URL}${path}`,
            body,
            myInit
        );
    }

    public static async deleteData(path: string, body?: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };

        return API.del(apiName, path, myInit);
    }

    public static async deleteDataEntiy(path: string, body?: any) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
            body,
        };

		return axios.delete(
            `${process.env.REACT_APP_API_URL}${path}`,
            myInit
        );    
	}

    public static async getData(path: string) {
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`,
            },
        };

        return axios.get(`${process.env.REACT_APP_API_URL}${path}`, myInit);
    }
}
