
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IBusinessAttribute } from '../business-attributes/interfaces/index';
import APIClient from '../../services/api/client';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface BusinessAttributeState {
    data: IBusinessAttribute[];
    status: Status;
    error: string | null;
}

const initialState: BusinessAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchBusinessEnum = createAsyncThunk(
    'singleBusiness/fetchBusinessEnum',
    async ({
        id,
    }: {
        id: string;
    }) => {
        const response = await APIClient.getData(`/company/enum/attribute/${id}/?limit=100000`);

        return response.data.results;
    }
);



export const businessEnumsSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchBusinessEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchBusinessEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchBusinessEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
