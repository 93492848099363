
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
// import { IPersonAttributeEnum } from '../persons-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonRelationshipAttributeState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchAllPortfolioPersonRelationshipEnum = createAsyncThunk(
    'portfolioPersonRelationshipEnums/fetchAllPortfolioPersonRelationshipEnum',
    async () => {
        const response = await APIClient.getData(`/portfolio/relationship/client/enum/?limit=100000`);

        return response.data.results;
    }
);



export const fetchAllPortfolioPersonRelationshipEnumSlice = createSlice({
    name: 'portfolioPersonRelationshipEnums',
    initialState,
    reducers: {		
		resetEntityEnumPersonRelationship: (state) => {
		state.data = [];
		state.status = Status.idle;
		state.error =  null;
	},},    extraReducers(builder) {
        builder
            .addCase(fetchAllPortfolioPersonRelationshipEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchAllPortfolioPersonRelationshipEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchAllPortfolioPersonRelationshipEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
