import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};


export const createPortfolioPersonAttribute = createAsyncThunk(
    'portfolioPersonAttributes/create',
    async (body: { label: string; name: string, index: number, type:number, is_unique:boolean, parent_attribute_id:number }) => {
        const response = await APIClient.postData('/portfolio/relationship/client/attribute/', body);
        return response.data;
    }
);


export const portfolioPersonsAttributesSlice = createSlice({
    name: 'portfolioPersonAttributes',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(createPortfolioPersonAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(createPortfolioPersonAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            })
            .addCase(createPortfolioPersonAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
