import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonRelationshipAttributeState {
    data: IPersonRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};




export const addRelationshiptAttribute = createAsyncThunk(
    'singlePersonRelationshipAttributes/addRelationshiptAttribute',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string, index: number}[];
    }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const neWData: any = []
        
        attributes.forEach(element => {
            neWData.push(            {
                name: element.name,
                value: element.value,
                index: Number(element.index )
            })

        });
        const response = await APIClient.postData(`/client/relationship/id/${id}/detail/`, {
            data: neWData,
        });

        return response.data;
    }
);
export const personsRelationshipAttributesValueSlice = createSlice({
    name: 'singlePersonRelationshipAttributes',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(addRelationshiptAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addRelationshiptAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(addRelationshiptAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            });
        
    },
});
