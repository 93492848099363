import { UserRoles } from '../../modules/users/enums';
import {
    signIn,
    currentUser,
    signOut,
    sendForgotPassword,
    setForgotPassword,
    changeTemporaryPassword,
} from './providers/aws';

// import { updateLogin } from './providers/cintac';

export default class Authentication {
    authenticated: boolean;

    user: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        role: UserRoles;
    } | null;

    constructor() {
        this.authenticated = false;
        this.user = null;
    }

    async login(username: string, password: string) {
        await signIn(username, password);

        this.authenticated = true;

        const user = await currentUser();

        // const response = await updateLogin();

        // const userData = { ...user };

        this.user = user;

        return Promise.resolve(user);
    }

    async logout() {
        await signOut();
        this.authenticated = false;
        this.user = null;

        return Promise.resolve(true);
    }

    isAuthenticated() {
        return this.authenticated;
    }

    async getCurrentUser() {
        const user = await currentUser();

        this.user = user;
        return Promise.resolve(this.user);
    }

    async forgotPassword(email: string) {
        await sendForgotPassword(email);
        this.user = null;
        this.authenticated = false;
        return Promise.resolve(true);
    }

    async changeforgotPassword(email: string, code: string, password: string) {
        await setForgotPassword(email, code, password);
        this.user = null;
        this.authenticated = false;
        return Promise.resolve(true);
    }

    async setTemporaryPassword(
        email: string,
        oldPassword: string,
        newPassword: string
    ) {
        const result = await changeTemporaryPassword(
            email,
            oldPassword,
            newPassword
        );

        if (result) {
            this.user = null;
            this.authenticated = false;
            return Promise.resolve(true);
        }

        return Promise.resolve(false);
    }
}
