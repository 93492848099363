/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { IItemAttribute } from '../../modules/common/interfaces';
import { useAppDispatch, RootState } from '../../store';
import { fetchBusinessEnum } from '../../modules/business-enums/businessEnum';
import { IBusinessAttribute } from '../../modules/business-attributes/interfaces/index';
import { fetchPersonsEnum } from '../../modules/persons-enums/personsEnum';
import { IPersonAttribute } from '../../modules/persons-attributes/interfaces/index';
import { IPortfolioAttribute } from '../../modules/portfolios-attributes/interfaces/index';
import { fetchPortfoliosEnum } from '../../modules/portfolios-enums/portfoliosEnum';
import { fetchPersonRelationshipEnum } from '../../modules/person-relationship-enums/personRelationshipEnum';
import { IPersonRelationshipAttribute } from '../../modules/persons-relationships-attributes/interfaces/index';
import { fetchCompanyRelationshipEnum } from '../../modules/companies-relationship-enums/companyRelationshipEnum';
import { getPortfolioPortfolioRelationshipEnum } from '../../modules/portfolio-portfolio-attributes/features/portfolioPortfolioAttributeEnumValue';
import { getPortfolioCompanyRelationshipEnum } from '../../modules/portfolio-companies-attributes/features/portfolioCompanyAttributeValueEnum';
import { getPortfolioPersonRelationshipEnum } from '../../modules/portfolio-persons-attributes/features/portfolioPersonAttributeValueEnum';

export default function EditableCell({
    value: initialValue,
    row: { index, original },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}: {
    value: any;
    row: any;
    column: any;
    updateMyData: (
        rowIndex: string | number,
        columnId: number,
        data: IItemAttribute,
        value: string
    ) => void;
}) {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [enums, setEnums] = useState<IBusinessAttribute[]>([]);
    const [enumsPersons, setEnumsPersons] = useState<IPersonAttribute[]>([]);
    const [enumsCompanies, setenumsCompanies] = useState<any[]>([]);
    const [enumsPortfolios, setEnumsPortfolios] = useState<
        IPortfolioAttribute[]
    >([]);

    // person enums
    const [enumsPersonRelationshipAttribute] = useState<
        IPersonRelationshipAttribute[]
    >([]);

    const [enumsPortfoliosPortfolio] = useState<IPortfolioAttribute[]>([]);
    const [enumsPortfoliosCompany] = useState<IPortfolioAttribute[]>([]);
    const [enumsPortfoliosPerson] = useState<IPortfolioAttribute[]>([]);

    const [isEnum, setIsEnum] = useState(false);
    const [companyttribute, setCompanyAttribute] = useState();
    const [personAttribute, setpersonAttribute] = useState();
    const [companiesAttribute, setcompaniesAttribute] = useState();
    const [portfolioAttribute, setPortfolioAttribute] = useState();
    const [portfolioPorfolioAttribute, setPortfolioPortfolioAttribute] =
        useState();
    const [portfolioCompanyAttribute, setPortfolioCompanyAttribute] =
        useState();
    const [portfolioPersonAttribute, setPortfolioPersonAttribute] = useState();

    const dispatch = useAppDispatch();

    const singleBusiness = useSelector(
        (state: RootState) => state.businessEnums
    );
    const singlePersons = useSelector((state: RootState) => state.personsEnums);

    const singlePortfolios = useSelector(
        (state: RootState) => state.portfoliosEnums
    );

    const singlePersonRelationshipAttributeEnum = useSelector(
        (state: RootState) => state.personsRelationshipsAttributesEnum
    );
    const companiesRelationshipsAttributesEnum = useSelector(
        (state: RootState) => state.companiesRelationshipsAttributesEnum
    );
    const portfoliosPesronRelationshipsAttributesEnum = useSelector(
        (state: RootState) => state.portfolioPersonRelationshipsAttributesEnum
    );

    const portfoliosCompanyelationshipsAttributesEnum = useSelector(
        (state: RootState) => state.portfolioCompanyRelationshipsAttributesEnum
    );

    const portfoliosPortfoliolationshipsAttributesEnum = useSelector(
        (state: RootState) =>
            state.portfolioPortfolioRelationshipsAttributesEnum
    );
    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, original, value);
    };

    const handleClick = () => {
        setIsEnum(false);
        if ('company_attribute' in original) {
            if (original.company_attribute.type === 2) {
                setIsEnum(true);
                dispatch(
                    fetchBusinessEnum({ id: original.company_attribute.id })
                );
                setCompanyAttribute(original.company_attribute.name);
            }
        } else if ('attribute' in original && original.client) {
            if (original.attribute.type === 2) {
                setIsEnum(true);
                dispatch(fetchPersonsEnum({ id: original.attribute.id }));
                setpersonAttribute(original.attribute.name);
            }
        } else if ('company_relationship_attribute' in original) {
            if (original.company_relationship_attribute.type === 2) {
                setIsEnum(true);
                dispatch(
                    fetchCompanyRelationshipEnum(
                        original.company_relationship_attribute.id
                    )
                );
                setcompaniesAttribute(
                    original.company_relationship_attribute.name
                );
            }
        } else if ('portfolio_client_relationship_attribute' in original) {
            if (original.portfolio_client_relationship_attribute.type === 2) {
                setIsEnum(true);
                dispatch(
                    getPortfolioPersonRelationshipEnum(
                        original.portfolio_client_relationship_attribute.id
                    )
                );
                setPortfolioPersonAttribute(
                    original.portfolio_client_relationship_attribute.name
                );
            }
        } else if ('portfolio_portfolio_relationship_attribute' in original) {
            if (
                original.portfolio_portfolio_relationship_attribute.type === 2
            ) {
                setIsEnum(true);
                dispatch(
                    getPortfolioPortfolioRelationshipEnum(
                        original.portfolio_portfolio_relationship_attribute.id
                    )
                );
                setPortfolioPortfolioAttribute(
                    original.portfolio_portfolio_relationship_attribute.name
                );
            }
        } else if ('attribute' in original && original.portfolio) {
            if (original.attribute.type === 2) {
                setIsEnum(true);
                dispatch(fetchPortfoliosEnum({ id: original.attribute.id }));
                setPortfolioAttribute(original.attribute.name);
            }
        } else if ('portfolio_company_relationship_attribute' in original) {
            if (original.portfolio_company_relationship_attribute.type === 2) {
                setIsEnum(true);
                dispatch(
                    getPortfolioCompanyRelationshipEnum(
                        original.portfolio_company_relationship_attribute.id
                    )
                );
                setPortfolioCompanyAttribute(
                    original.portfolio_company_relationship_attribute.name
                );
            }
        }
        // company Attribute
        else if (
            'client_relationship_attribute' in original &&
            original.client_relationship_attribute
        ) {
            if (original.client_relationship_attribute.type === 2) {
                setIsEnum(true);

                dispatch(
                    fetchPersonRelationshipEnum(
                        original.client_relationship_attribute.id
                    )
                );

                // setPersonRelationshipAttributes(original.client_relationship_attribute.name);
            }
        }
    };

    useEffect(() => {
        let exist = false;
        if (
            singlePersons.status !== 'idle' &&
            singlePersons.status === 'success'
        ) {
            if (enumsPersons.length > 0) {
                singlePersons.data.forEach((e: any) => {
                    enumsPersons.forEach((en: IPersonAttribute) => {
                        const data = en as unknown as IPersonAttribute[];
                        data.map((dat: any) => {
                            if (dat.name === e.name) {
                                exist = true;
                            }
                            return false;
                        });
                    });
                });
            }
            if (!exist) {
                setEnumsPersons([
                    ...enumsPersons,
                    singlePersons.data as unknown as IPersonAttribute,
                ]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singlePersons.data, singlePersons.status]);

    useEffect(() => {
        let exist = false;
        if (
            singlePortfolios.status !== 'idle' &&
            singlePortfolios.status === 'success'
        ) {
            if (enumsPortfolios.length > 0) {
                singlePortfolios.data.forEach((e: any) => {
                    enumsPortfolios.forEach((en: IPortfolioAttribute) => {
                        const data = en as unknown as IPortfolioAttribute[];
                        data.map((dat: any) => {
                            if (dat.name === e.name) {
                                exist = true;
                            }
                            return false;
                        });
                    });
                });
            }
            if (!exist) {
                setEnumsPortfolios([
                    ...enumsPortfolios,
                    singlePortfolios.data as unknown as IPortfolioAttribute,
                ]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singlePortfolios.data, singlePortfolios.status]);

    useEffect(() => {
        let exist = false;
        if (
            singleBusiness.status !== 'idle' &&
            singleBusiness.status === 'success'
        ) {
            if (enums.length > 0) {
                singleBusiness.data.forEach((e: any) => {
                    enums.forEach((en: IBusinessAttribute) => {
                        const data = en as unknown as IBusinessAttribute[];
                        data.map((dat: any) => {
                            if (dat.name === e.name) {
                                exist = true;
                            }
                            return false;
                        });
                    });
                });
            }
            if (!exist) {
                setEnums([
                    ...enums,
                    singleBusiness.data as unknown as IBusinessAttribute,
                ]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleBusiness.data, singleBusiness.status]);

    useEffect(() => {
        let exist = false;
        if (
            companiesRelationshipsAttributesEnum.status !== 'idle' &&
            companiesRelationshipsAttributesEnum.status === 'success'
        ) {
            if (enums.length > 0) {
                companiesRelationshipsAttributesEnum.data.forEach((e: any) => {
                    enums.forEach((en: IBusinessAttribute) => {
                        const data = en as unknown as IBusinessAttribute[];
                        data.map((dat: any) => {
                            if (dat.name === e.name) {
                                exist = true;
                            }
                            return false;
                        });
                    });
                });
            }
            if (!exist) {
                setenumsCompanies([
                    ...enums,
                    companiesRelationshipsAttributesEnum.data,
                ]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        companiesRelationshipsAttributesEnum.data,
        companiesRelationshipsAttributesEnum.status,
    ]);

    // Person Attribute Relationship

    useEffect(() => {
        // let exist = false;
        if (
            singlePersonRelationshipAttributeEnum.status !== 'idle' &&
            singlePersonRelationshipAttributeEnum.status === 'success'
        ) {
            if (enumsPersonRelationshipAttribute.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                singlePersonRelationshipAttributeEnum.data.forEach((e: any) => {
                    enumsPersonRelationshipAttribute.forEach((en: any) => {
                        const data = en;
                        if (data.enum.length > 0) {
                            // data.enum.map((dat: any) => {
                            // 	console.log(dat, '***');
                            // 	// if (dat.name === e.name) {
                            // 	// 	exist = true;
                            // 	// }
                            // 	// return false;
                            // });
                        }
                    });
                });
            }
            // if (!exist) {
            //     setEnumsPersonRelationshipAttribute([
            //         ...enumsPersonRelationshipAttribute,
            //         singlePersonRelationshipAttributeEnum.data[0] as unknown as any,
            //     ]);
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        singlePersonRelationshipAttributeEnum.data,
        singlePersonRelationshipAttributeEnum.status,
    ]);
    useEffect(() => {
        // let exist = false;
        if (
            portfoliosCompanyelationshipsAttributesEnum.status !== 'idle' &&
            portfoliosCompanyelationshipsAttributesEnum.status === 'success'
        ) {
            if (enumsPortfoliosCompany.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                portfoliosCompanyelationshipsAttributesEnum.data.forEach(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    (e: any) => {
                        enumsPortfoliosCompany.forEach((en: any) => {
                            const data = en;
                            if (data.enum.length > 0) {
                                // data.enum.map((dat: any) => {
                                // 	console.log(dat, '***');
                                // 	// if (dat.name === e.name) {
                                // 	// 	exist = true;
                                // 	// }
                                // 	// return false;
                                // });
                            }
                        });
                    }
                );
            }
            // if (!exist) {
            //     setEnumsPersonRelationshipAttribute([
            //         ...enumsPersonRelationshipAttribute,
            //         singlePersonRelationshipAttributeEnum.data[0] as unknown as any,
            //     ]);
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        portfoliosCompanyelationshipsAttributesEnum.data,
        portfoliosCompanyelationshipsAttributesEnum.status,
    ]);

    useEffect(() => {
        // let exist = false;
        if (
            portfoliosPesronRelationshipsAttributesEnum.status !== 'idle' &&
		
            portfoliosPesronRelationshipsAttributesEnum.status === 'success'
        ) {
            if (enumsPortfoliosPerson.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                portfoliosPesronRelationshipsAttributesEnum.data.forEach(
                    (e: any) => {
                        enumsPortfoliosPerson.forEach((en: any) => {
                            const data = en;
                            if (data.enum.length > 0) {
                                // data.enum.map((dat: any) => {
                                // 	console.log(dat, '***');
                                // 	// if (dat.name === e.name) {
                                // 	// 	exist = true;
                                // 	// }
                                // 	// return false;
                                // });
                            }
                        });
                    }
                );
            }
            // if (!exist) {
            //     setEnumsPersonRelationshipAttribute([
            //         ...enumsPersonRelationshipAttribute,
            //         singlePersonRelationshipAttributeEnum.data[0] as unknown as any,
            //     ]);
            // }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        portfoliosPesronRelationshipsAttributesEnum.data,
        portfoliosPesronRelationshipsAttributesEnum.status,
    ]);

    useEffect(() => {
        // let exist = false;
        if (
            portfoliosPortfoliolationshipsAttributesEnum.status !== 'idle' &&
            portfoliosPortfoliolationshipsAttributesEnum.status === 'success'
        ) {
            if (enumsPortfoliosPortfolio.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                portfoliosPortfoliolationshipsAttributesEnum.data.forEach(
                    (e: any) => {
                        enumsPortfoliosPortfolio.forEach((en: any) => {
                            const data = en;
                            if (data.enum.length > 0) {
                                // data.enum.map((dat: any) => {
                                // 	console.log(dat, '***');
                                // 	// if (dat.name === e.name) {
                                // 	// 	exist = true;
                                // 	// }
                                // 	// return false;
                                // });
                            }
                        });
                    }
                );
            }
            // if (!exist) {
            //     setEnumsPersonRelationshipAttribute([
            //         ...enumsPersonRelationshipAttribute,
            //         singlePersonRelationshipAttributeEnum.data[0] as unknown as any,
            //     ]);
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        portfoliosPortfoliolationshipsAttributesEnum.data,
        portfoliosPortfoliolationshipsAttributesEnum.status,
    ]);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        updateMyData(index, id, original, value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const validateTypeAttribute = (key: number) => {
        let val = '';
        switch (key) {
            case 3:
                val = 'date';
                break;
            case 4:
                val = 'number';
                break;
            default:
                val = 'text';
                break;
        }
        return val;
    };

    return isEnum === false ? (
        original.company_attribute ? (
            <input
                type={validateTypeAttribute(original.company_attribute.type)}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.company_attribute.type === 3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        ) : original.client_relationship_attribute ? (
            <input
                // eslint-disable-next-line no-nested-ternary

                type={validateTypeAttribute(
                    original.client_relationship_attribute.type
                )}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.client_relationship_attribute.type === 3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        ) : original.portfolio_client_relationship_attribute ? (
            <input
                // eslint-disable-next-line no-nested-ternary

                type={validateTypeAttribute(
                    original.portfolio_client_relationship_attribute.type
                )}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.portfolio_client_relationship_attribute.type === 3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        ) : original.portfolio_company_relationship_attribute ? (
            <input
                // eslint-disable-next-line no-nested-ternary

                type={validateTypeAttribute(
                    original.portfolio_company_relationship_attribute.type
                )}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.portfolio_company_relationship_attribute.type === 3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        ) : original.portfolio_portfolio_relationship_attribute ? (
            <input
                // eslint-disable-next-line no-nested-ternary

                type={validateTypeAttribute(
                    original.portfolio_portfolio_relationship_attribute.type
                )}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.portfolio_portfolio_relationship_attribute.type ===
                    3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        ) : original.company_relationship_attribute ? (
            <input
                // eslint-disable-next-line no-nested-ternary

                type={validateTypeAttribute(
                    original.company_relationship_attribute.type
                )}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.company_relationship_attribute.type === 3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        ) : (
            <input
                // eslint-disable-next-line no-nested-ternary

                type={validateTypeAttribute(original.attribute.type)}
                className="bg-transparent border-0 shadow-none"
                value={
                    original.attribute.type === 3
                        ? value.substring(0, 10)
                        : value
                }
                onChange={onChange}
                onClick={handleClick}
                onBlur={onBlur}
            />
        )
    ) : (
        <select onClick={handleClick} onChange={onChange}>
            {singleBusiness.status === 'loading' ||
                singlePersons.status === 'loading' 		||
                singlePersonRelationshipAttributeEnum.status === 'loading' && 
				(
                    <option> cargando...</option>
                )}
            <option value=""> Seleccione un valor</option>
            {enums.map((e: any) =>
                e.map(
                    (en: any) =>
                        en.name === companyttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}
            {enumsPersons.map((e: any) =>
                e.map(
                    (en: any) =>
                        // console.log(en, personAttribute, '**')

                        en.name === personAttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}

            {enumsPortfolios.map((e: any) =>
                e.map(
                    (en: any) =>
                        en.name === portfolioAttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}
            {enumsCompanies.map((e: any) =>
                e.map(
                    (en: any) =>
                        en.name === companiesAttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}
            {
                // console.log(enumsPersonRelationshipAttribute, singlePersonRelationshipAttributeEnum)
                singlePersonRelationshipAttributeEnum.data.length > 0 &&
                    singlePersonRelationshipAttributeEnum.data.map((e: any) =>
                        e.enum.map(
                            (en: any) => (
                                // console.log(en,singlePersonRelationshipAttributeEnum, '**')
                                // en.name === personRelationshipAttributes &&
                                // en.enum.map((data: any) => (
                                // console.log(en.name)
                                <option
                                    key={en.id}
                                    value={en.name}
                                    onChange={onChange}
                                >
                                    {en.name}
                                </option>
                            )
                            // ))
                        )
                    )
            }

            {enumsPortfoliosPerson.map((e: any) =>
                e.map(
                    (en: any) =>
                        en.name === portfolioPersonAttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}
            {
                // console.log(enumsPersonRelationshipAttribute, singlePersonRelationshipAttributeEnum)
                portfoliosPesronRelationshipsAttributesEnum.data.length > 0 &&
                    portfoliosPesronRelationshipsAttributesEnum.data.map(
                        (e: any) =>
                            e.enum.map(
                                (en: any) => (
                                    // console.log(en,singlePersonRelationshipAttributeEnum, '**')
                                    // en.name === personRelationshipAttributes &&
                                    // en.enum.map((data: any) => (
                                    // console.log(en.name)
                                    <option
                                        key={en.id}
                                        value={en.name}
                                        onChange={onChange}
                                    >
                                        {en.name}
                                    </option>
                                )
                                // ))
                            )
                    )
            }

            {enumsPortfoliosCompany.map((e: any) =>
                e.map(
                    (en: any) =>
                        en.name === portfolioCompanyAttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}
            {
                // console.log(enumsPersonRelationshipAttribute, singlePersonRelationshipAttributeEnum)
                portfoliosCompanyelationshipsAttributesEnum.data.length > 0 &&
                    portfoliosCompanyelationshipsAttributesEnum.data.map(
                        (e: any) =>
                            e.enum.map(
                                (en: any) => (
                                    // console.log(en,singlePersonRelationshipAttributeEnum, '**')
                                    // en.name === personRelationshipAttributes &&
                                    // en.enum.map((data: any) => (
                                    // console.log(en.name)
                                    <option
                                        key={en.id}
                                        value={en.name}
                                        onChange={onChange}
                                    >
                                        {en.name}
                                    </option>
                                )
                                // ))
                            )
                    )
            }
            {enumsPortfoliosPortfolio.map((e: any) =>
                e.map(
                    (en: any) =>
                        en.name === portfolioPorfolioAttribute &&
                        en.enum.map((data: any) => (
                            <option
                                key={data.id}
                                value={data.name}
                                onChange={onChange}
                            >
                                {data.name}
                            </option>
                        ))
                )
            )}
            {
                // console.log(enumsPersonRelationshipAttribute, singlePersonRelationshipAttributeEnum)
                portfoliosPortfoliolationshipsAttributesEnum.data.length > 0 &&
                    portfoliosPortfoliolationshipsAttributesEnum.data.map(
                        (e: any) =>
                            e.enum.map(
                                (en: any) => (
                                    // console.log(en,singlePersonRelationshipAttributeEnum, '**')
                                    // en.name === personRelationshipAttributes &&
                                    // en.enum.map((data: any) => (
                                    // console.log(en.name)
                                    <option
                                        key={en.id}
                                        value={en.name}
                                        onChange={onChange}
                                    >
                                        {en.name}
                                    </option>
                                )
                                // ))
                            )
                    )
            }
        </select>
    );
}
