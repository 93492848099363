/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

class ErrorBoundary extends React.Component<
    {},
    { hasError: boolean; errorMessage: string }
> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.    return { hasError: true };  }
        return { hasError: true, errorMessage: error.toString() };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="bg-red-300 p-6 max-w-md shadow-md shadow-slate-700">
                    <h1 className="text-xl font-bold text-center">
                        Something went wrong.
                    </h1>
                    <p className="mt-10">{this.state.errorMessage}</p>
                </div>
            );
        }

        return this.props.children; // Note: Rest of the app comes after here.
    }
}

export default ErrorBoundary;
