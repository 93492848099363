import { useTranslation } from 'react-i18next';
// import Loader from '../../components/elements/Loaders/Loader';
import LoadingAnimated from '../../components/elements/Loading';

export default function Loading() {
    const { t } = useTranslation();
    return (
        <div>
            <div className="min-h-screen flex flex-col justify-center items-center mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <LoadingAnimated message={t('loading.message')} />
            </div>
        </div>
    );
}
