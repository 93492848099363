/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../store';

import SideCreate from '../../../../components/elements/SideCreate';
import { CreateCompanyAttributeRelationship } from '../../../../components/CreateAttributeRelationship/index';
import { createCompanyRelationshipAttribute } from '../../../../modules/company-relationships-attributes/features/companyRelationshipAttributeSlice';

export default function CreateCompanyRelationshipAttribute({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    return (
        <SideCreate
            show={show}
            title={t('attributes.create.title')}
            toggle={toggle}
        >
            <CreateCompanyAttributeRelationship
                entity={4}
                create={(body) =>
                    dispatch(createCompanyRelationshipAttribute(body)).unwrap()
                }
                show={show}
            />
        </SideCreate>
    );
}
