import React, { SVGProps } from 'react';

export interface IActionLinkProps {
    label: string;
    icon?: string | undefined;
    svgIcon?: SVGProps<SVGElement> | undefined;
    path?: string;
    showLabel?: boolean;
    className?: string;
    iconPosition?: 'left' | 'right';
}

/**
 * Header action item
 * @param label Label of the item
 * @param icon Icon of the item
 * @param path Path of the item
 * @param action Action if action is present the item is a button
 * @param showLabel Show label or not
 * @param className Class name
 * @param svgIcon SVG icon
 *
 */
export default function ActionLink({
    label,
    icon,
    path,
    showLabel,
    className,
    svgIcon,
    iconPosition,
}: IActionLinkProps) {
    if (!showLabel && !icon && !svgIcon) {
        return (
            <div className="bg-red-500 text-white symlab-action-link-item__error ">
                ActionLink Error: icon or svgIcon is required when showLabel is
                false.
            </div>
        );
    }

    if (path === '') {
        return (
            <div className="bg-red-500 text-white symlab-action-link-item__error">
                ActionLink Error: path parameter is required. path cannot be
                empty.
            </div>
        );
    }

    if (path && showLabel && icon) {
        return (
            <a
                className={`flex symlab-action-link-item ${className}`}
                href={path}
                title={label}
            >
                <img
                    className="w-6 mr-1 symlab-action-link-item__icon symlab-action-link-item__icon-img"
                    src={icon}
                    alt={label}
                />{' '}
                <span className="symlab-action-link-item__label">{label}</span>
            </a>
        );
    }

    if (path && showLabel && svgIcon) {
        return (
            <a
                className={`symlab-action-link-item flex ${className}`}
                href={path}
                title={label}
            >
                {iconPosition === 'left' && (
                    <div className="symlab-action-link-item__icon symlab-action-link-item__icon-svg symlab-action-link-item__icon-left">
                        {svgIcon}
                    </div>
                )}{' '}
                <span
                    className={`${
                        iconPosition === 'left' ? 'ml-1' : 'mr-1'
                    } symlab-action-link-item__label`}
                >
                    {label}
                </span>{' '}
                {iconPosition === 'right' && (
                    <div className="symlab-action-link-item__icon symlab-action-link-item__icon-svg symlab-action-link-item__icon-right">
                        {svgIcon}
                    </div>
                )}
            </a>
        );
    }

    if (path && showLabel) {
        return (
            <a className={`symlab-action-link-item ${className}`} href={path}>
                <span className="symlab-action-link-item__label">{label}</span>
            </a>
        );
    }

    if (path && !showLabel && icon) {
        return (
            <a
                className={`symlab-action-link-item symlab-action-link-item__no-label ${className}`}
                href={path}
                title={label}
            >
                <img
                    className="w-6 symlab-action-link-item__icon symlab-action-link-item__icon-img"
                    src={icon}
                    alt={label}
                />
            </a>
        );
    }

    if (path && !showLabel && svgIcon) {
        return (
            <a
                className={`symlab-action-link-item symlab-action-link-item__no-label ${className}`}
                href={path}
                title={label}
            >
                <div className="symlab-action-link-item__icon symlab-action-link-item__icon-svg">
                    {svgIcon}
                </div>
            </a>
        );
    }

    return (
        <div className="bg-red-500 text-white symlab-action-link-item__error">
            ActionLink Error: Unkown error, check parameters.
        </div>
    );
}

ActionLink.defaultProps = {
    icon: undefined,
    path: '/',
    className: '',
    showLabel: true,
    svgIcon: undefined,
    iconPosition: 'left',
};
