export interface ILogoProps {
    title: string;
    logo?: string;
    link?: string;
    isMobile?: boolean;
}

export default function Logo({ title, logo, link, isMobile }: ILogoProps) {
    return (
        <div
            className={`flex justify-start lg:w-0 lg:flex-1 ${
                isMobile ? 'symlab-header-logo__mobile' : 'symlab-logo__desktop'
            }`}
        >
            <a href={link}>
                <span className="sr-only">{title}</span>
                {logo !== '' ? (
                    <img
                        className="symlab-header-logo__img"
                        src={logo}
                        alt={title}
                        style={{
                            maxWidth: 300,
                        }}
                    />
                ) : (
                    <h1 className="text-2xl symlab-header-logo__title">
                        {title}
                    </h1>
                )}
            </a>
        </div>
    );
}

Logo.defaultProps = {
    logo: '',
    link: '/',
    isMobile: false,
};
