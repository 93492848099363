export default function RoleCheck({
    currentRole,
    allowedRoles,
    children,
}: {
    allowedRoles: string[];
    currentRole: string;
    children: React.ReactNode;
}) {
    if (allowedRoles.includes(currentRole)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }
    return null;
}
