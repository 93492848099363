import Loader from '../Loaders/Loader';

export default function LoadingAnimated({ message }: { message: string }) {
    return (
        <div className="flex flex-col justify-center items-center mt-8">
            <Loader />
            <h1>{message}</h1>
        </div>
    );
}
