/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';

import TableSearch from '../../../components/Table/TableSearch';
// import Create from './components/Create';

import { fetchPersonRelationshipAttributesValues } from '../../../modules/persons-relationships-attributes/features/personRelationshipAttributesValuesSlice';
import Modal from '../../../components/Modal';
import { validateDate, validateType } from '../../../utils/helpers';
import { TypesAttribute } from '../../../components/enums/enums';
import AttributesTable from './components/AttributesTable';
import { fetchCompanyRelationshipAttributesValues, resetValuesCompaniesRel } from '../../../modules/company-relationships-attributes/features/companyRelationshipAttributesValuesSlice';
import {
    fetchCompaniesRelationshipAttributes,
} from '../../../modules/company-relationships-attributes/features/companiesRelationshipAttributesSlice';
import { fetchPersonBusinessRelationships } from '../../../modules/person-business-relationships/features/personBusinessRelationshipSlice';
import {
    addCompanyRelationshiptAttribute,
} from '../../../modules/company-relationships-attributes/features/companyRelationshipAttributeSlice';
import { fetchCompanyRelationshipEnum } from '../../../modules/companies-relationship-enums/companyRelationshipEnum';
import { fetchCompanyRelationshipRelAttributesValues } from '../../../modules/company-relationships-attributes/features/companyRelAttributesSlice';
import { fetchSinglePerson } from '../../../modules/persons/features/singlePersonSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    name: string;
    index: number;
	idAttribute: number;

};

export default function ListCompaniesRelationshipAttributesValues() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm<FormData>();

    const { id, companyId } = useParams();

    const [getAvailableAttributes, setGetAvailableAttributes] = useState(true);
    // const [newAttributeStatus, setNewAttributeStatus] = useState('idle');
    const [showCreate, setShowCreate] = useState(false);

    const [isEnum, setIsEnum] = useState(false);
    const [isUnique, setIsUnique] = useState(false);
    const [idAttribute, setIdAttribute] = useState('');
    // const [availableAttributes, setAvailableAttributes] = useState<
    //     IPersonAttribute[]
    // >([]);
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    const companiesRelationshipAttributes = useSelector(
        (state: RootState) => state.companiesRelationshipsAttributes
    );
    const businessRelationships = useSelector(
        (state: RootState) => state.businessPortfolioRelationships
    );
    const companiesRelationshipsAttributesValues = useSelector(
        (state: RootState) => state.companiesRelationshipAttributeValues
    );
    const companiesRelationshipValues = useSelector(
        (state: RootState) => state.companyRelationshipValue
    );

    const singleRelationshipEnum = useSelector(
        (state: RootState) => state.companiesRelationshipsAttributesEnum
    );
    const companyRelationshipsAttribute = useSelector(
        (state: RootState) => state.companyRelationshipsAttribute
    );
    // const singlePerson = useSelector((state: RootState) => state.singlePerson);
    const singleCompany = useSelector((state: RootState) => state.singlePerson)	;
    
	const companyRelAttributes = useSelector((state: RootState) => state.companyRelAttributes);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if(singleCompany.status === 'idle'){
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			dispatch(fetchSinglePerson(companyId!))
		}
	}, [companyId, dispatch, singleCompany.status])

	useEffect(() => {
		if(companyRelAttributes.status === 'idle' && singleCompany.status === 'success'){
			const dataRel =  singleCompany.companyRelations.filter(attribute => attribute.idRelationship === Number(id))
			dispatch(fetchCompanyRelationshipRelAttributesValues(dataRel[0].relationshipTypeId))
		}
	 
	}, [companyRelAttributes.status, dispatch, id, singleCompany.companyRelations, singleCompany.personRelations, singleCompany.status])
	
    useEffect(() => {
        // Filter available attributes for new attribute
        if (
            getAvailableAttributes &&
            companiesRelationshipAttributes.data.length > 0
            // singlePerson.attributes.length > 0
        ) {
            // setAvailableAttributes(companiesRelationshipAttributes.data);
            // setNewAttributeStatus('success');
            setGetAvailableAttributes(false);
        }
    }, [
        companiesRelationshipAttributes.data,
        // singlePerson.attributes,
        getAvailableAttributes,
    ]);

    useEffect(() => {
        if (companiesRelationshipsAttributesValues.status === 'idle' && id) {
            dispatch(fetchCompanyRelationshipAttributesValues(id || ''));
        }
    }, [dispatch, id, companiesRelationshipsAttributesValues.status]);

    useEffect(() => {
        if (
            companiesRelationshipAttributes.status === 'idle' &&
            businessRelationships.status === 'idle'
        ) {
            dispatch(fetchCompaniesRelationshipAttributes());
            dispatch(fetchPersonBusinessRelationships());
        }
    }, [
        companiesRelationshipAttributes.status,
        businessRelationships.status,
        dispatch,
    ]);
    useEffect(() => {
        if (
            companiesRelationshipAttributes.status === 'idle' 
        ) {
            dispatch(fetchCompaniesRelationshipAttributes());
        }
    }, [
        companiesRelationshipAttributes.status,
        dispatch,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: t('common.value').toString(),
                accessor: 'data',
            },
            {
                Header: 'Indice',
                accessor: 'index',
            },
        ],

        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: companiesRelationshipsAttributesValues.data || [],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
    } = tableInstance;

    const [msgError, setMsgError] = useState('');
    const [type, setType] = useState(0);
    const value = watch('value');

    const [allData ] = useState();
    const onSubmit = async (data: FormData) => {
        try {
            if (data.value === 'N/A') {
                // dispatch(
                //     createCompanyRelationshipAttributeEnum({
                //         name: data.value,
                //         // attribute: Number(idAttribute),
                //         company_relationship_attribute: Number(idAttribute),
                //     })
                // );
                // setValue('index', 1);
                // setAddRequestStatus('loading');
                // // eslint-disable-next-line @typescript-eslint/no-explicit-any
                // setAllData(data as any);
            } else {
                if (type === TypesAttribute.Number) {
                    // eslint-disable-next-line no-param-reassign
                    data.value = Number(data.value);
                }
                if (type === TypesAttribute.Date) {
                    let splitDate = '';
                    if (value) {
                        splitDate = value.split('-');
                    }

                    const isValidDate = validateDate(splitDate, value);

                    if (isValidDate) {
                        // eslint-disable-next-line no-param-reassign
                        data.value = isValidDate;
                    } else {
                        setMsgError(
                            'Formato fecha invalido debe ingresar dd-mm-yyyy'
                        );
                    }
                }

                // eslint-disable-next-line no-param-reassign
                data.index = data.index || 1;
				// eslint-disable-next-line no-param-reassign
				data.idAttribute = Number(idAttribute);

                setAddRequestStatus('loading');
                dispatch(
                    addCompanyRelationshiptAttribute({
                        id: id ?? '',
                        attributes: [data],
                    })
                );

                //  dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
                // await create(body);
                setAddRequestStatus('success');
                reset();
                setShowCreate(false);
            }
        } catch (error) {
            // console.log(error);
            setAddRequestStatus('error');
        }
    };
    useEffect(() => {
        if (companyRelationshipsAttribute.status === 'success') {
            dispatch(
                addCompanyRelationshiptAttribute({
                    id: id ?? '',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    attributes: [allData as any],
                })
            );
            dispatch(fetchPersonRelationshipAttributesValues(id ?? ''));
            // await create(body);
            setAddRequestStatus('success');
            reset();
            setShowCreate(false);
        }
        if (companyRelationshipsAttribute.status === 'error') {
            setAddRequestStatus('error');
        }
    }, [allData, companyRelationshipsAttribute.status, dispatch, id, reset]);


    useEffect(() => {
        if (companiesRelationshipValues.status === 'success') {
			setTimeout(() => {
				
				dispatch(resetValuesCompaniesRel());
				dispatch(fetchCompanyRelationshipAttributesValues(id ?? ''));
			}, 2000);
        }
    }, [companiesRelationshipValues.status,dispatch, id]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('value', '');
        setIsEnum(false);
        setIsUnique(false);
		if (e.target.selectedOptions[0].getAttribute('data-type') === '1') {
            setType(1);
        }
		if (e.target.selectedOptions[0].getAttribute('data-type') === '2') {
            setType(2);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '3') {
            setType(3);
        }
        if (e.target.selectedOptions[0].getAttribute('data-type') === '4') {
            setType(4);
        }
        if (
            e.target.selectedOptions[0].getAttribute('data-unique') === 'true'
        ) {
            setIsUnique(true);
        }

        if (e.target.selectedOptions[0].getAttribute('data-enum') === 'true') {
            setIsEnum(true);
			setIdAttribute(
                e.target.selectedOptions[0].getAttribute(
                    'data-id'
                ) as SetStateAction<string>
            );
        }
		setIdAttribute(
			e.target.selectedOptions[0].getAttribute(
				'data-id'
			) as SetStateAction<string>
		);
    };

    useEffect(() => {
        if (idAttribute) {
            dispatch(fetchCompanyRelationshipEnum(Number(idAttribute)));
        }
    }, [dispatch, idAttribute]);

    return (
        <div className="relative mb-20">
            {/* <Create show={showCreate} toggle={() => setShowCreate(false)} /> */}

            <PageHeader title="Valor atributo de relación jurídica" />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {
			companiesRelationshipsAttributesValues.status === 'loading'  && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {companiesRelationshipsAttributesValues.status === 'success' &&
                companiesRelationshipsAttributesValues.data &&
                companiesRelationshipsAttributesValues.data.length === 0 && (
                    <h3 className="text-lg text-center">
                        No existen valores para esta relación
                    </h3>
                )}
            <div>
                <Button
                    className="absolute right-0 top-0 z-10"
                    label={t('attributes.create.title')}
                    type="button"
                    onClick={() => setShowCreate(!showCreate)}
                />
            </div>
            {companiesRelationshipsAttributesValues.status === 'success' &&
			companyRelAttributes.status === 'success' &&
                companiesRelationshipsAttributesValues.data.length > 0 && (
                    <AttributesTable
                        clientId={id || ''}
                        data={companiesRelationshipsAttributesValues.data}
                    />
                )}

            {showCreate && (
                <Modal
                    setShowModal={setShowCreate}
                    onClose={() => setShowCreate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('attributes.create.addNew')}
                        </h3>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('attributes.title')} *
                                        <div className="mt-1">
                                            <select
                                                id="name"
                                                className={`${
                                                    errors.name
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register('name', {
                                                    required: true,
                                                    onChange: handleChange,
                                                })}
                                            >
                                                <option value="">
                                                    {t(
                                                        'attributes.create.selectAnAttribute'
                                                    )}
                                                </option>
                                                {companyRelAttributes.status ===
                                                    'success' &&
                                                    companyRelAttributes.data.length >
                                                        0 &&
														companyRelAttributes.data.map(
                                                        (attribute) => (
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.name
                                                                }
                                                                data-enum={
                                                                    attribute.type ===
                                                                    2
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-type={
                                                                    attribute.type
                                                                }
                                                                data-unique={
                                                                    attribute.is_unique
                                                                        ? 'true'
                                                                        : 'false'
                                                                }
                                                                data-id={
                                                                    attribute.id
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                {/* END Attributes Selection */}

                                {isEnum ? (
                                    <div className="w-full mt-4 mb-4">
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Valores de atributo *
                                            <div className="mt-1">
                                                <select
                                                    id="value"
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'attributes.create.selectAnAttribute'
                                                        )}
                                                    </option>
                                                    {singleRelationshipEnum.status ===
                                                        'success' &&
                                                        singleRelationshipEnum
                                                            .data.length > 0 &&
                                                        singleRelationshipEnum.data.map(
                                                            (attribute) =>
                                                                attribute.enum
                                                                    .length >
                                                                0 ? (
                                                                    attribute.enum.map(
                                                                        (
                                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                            e: any
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    e.id
                                                                                }
                                                                                value={
                                                                                    e.name
                                                                                }
                                                                                data-enum={
                                                                                    attribute.type ===
                                                                                    2
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-unique={
                                                                                    attribute.is_unique
                                                                                        ? 'true'
                                                                                        : 'false'
                                                                                }
                                                                                data-id={
                                                                                    e.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <option
                                                                        key={
                                                                            attribute.id
                                                                        }
                                                                        value="N/A"
                                                                    >
                                                                        No existen valores
                                                                    </option>
                                                                )
                                                        )}
                                                </select>
                                            </div>
                                        </label>

                                        {errors.name && (
                                            <p className="text-red-600 ml-2">
                                                {t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <label
                                            htmlFor="value"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {t('common.value')} *
                                            <div className="mt-1">
                                                <input
                                                    id="value"
                                                    type={validateType(type)}
                                                    className={`${
                                                        errors.value
                                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                            : ''
                                                    }`}
                                                    {...register('value', {
                                                        required: true,
                                                    })}
                                                />
                                                {errors.value && (
                                                    <p className="text-red-600 ml-2">
                                                        {errors.value.type ===
                                                            'required' &&
                                                            t(
                                                                'common.errors.fieldRequired'
                                                            )}
                                                    </p>
                                                )}
                                                {msgError !== '' && (
                                                    <p className="text-red-600 ml-2">
                                                        {msgError}
                                                    </p>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                )}
                                {/*  */}

                                {isEnum === true && isUnique === false && (
                                    <div className="mt-3">
                                        <label
                                            htmlFor="index"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            {' '}
                                            Indice
                                            <div className="mt-1">
                                                <input
                                                    {...register('index', {})}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    id="index"
                                                    className="border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )}
                                <div className="mt-4">
                                    {addRequestStatus === 'error' && (
                                        <p className="text-red-600 mb-2">
                                            {t('attributes.create.error')}
                                        </p>
                                    )}
                                    {addRequestStatus === 'success' && (
                                        <p className="text-green-600 mb-2">
                                            {t('attributes.create.success')}
                                        </p>
                                    )}
                                    <button
                                        disabled={
                                            addRequestStatus === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {addRequestStatus === 'loading'
                                            ? t('wait.message')
                                            : t('common.save')}
                                    </button>
                                    <Button
                                        type="button"
                                        variant="link"
                                        className="w-full mt-10"
                                        label={t('common.cancel')}
                                        onClick={() => setShowCreate(false)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
