
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import APIClient from '../../services/api/client';
// import { ICompanyAttributeEnum } from '../persons-attributes/interfaces/index';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyRelationshipAttributeState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    status: Status;
    error: string | null;
}

const initialState: CompanyRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchAllCompanyRelationshipEnum = createAsyncThunk(
    'companyRelationshipEnums/fetchAllCompanyRelationshipEnum',
    async () => {
        const response = await APIClient.getData(`/company/relationship/enum/?limit=100000`);

        return response.data.results;
    }
);



export const fetchAllCompanyRelationshipEnumSlice = createSlice({
    name: 'companyRelationshipEnums',
    initialState,
    reducers: {		
		resetEntityEnumCompanyRelationship: (state) => {
		state.data = [];
		state.status = Status.idle;
		state.error =  null;
	},},    extraReducers(builder) {
        builder
            .addCase(fetchAllCompanyRelationshipEnum.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchAllCompanyRelationshipEnum.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchAllCompanyRelationshipEnum.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
    },
});
