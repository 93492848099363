/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SideCreate from '../../../../components/elements/SideCreate';

import { UserRoles } from '../../../../modules/users/enums';
import { RootState } from '../../../../store';
import {
    fetchUsers,
    inviteUserAction,
} from '../../../../modules/users/features';
import { Button } from '../../../../components/elements/Buttons';

type FormData = {
    email: string;
    role: string;
    firstName: string;
    lastName: string;
};

export default function InviteUser({
    show = false,
    toggle,
}: {
    show: boolean;
    toggle: () => void;
}) {
    const dispatch = useDispatch();
    const userInvite = useSelector((state: RootState) => state.userInvite);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormData>();

    const { t } = useTranslation();

    const [success, setSuccess] = useState(false);

    const onSubmit = (data: FormData) => {
        dispatch(
            inviteUserAction({
                email: data.email,
                attributes: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    role: data.role,
                },
            })
        );
    };

    const onHide = () => {
        reset();
        toggle();
    };

    useEffect(() => {
        if (userInvite.status === 'success') {
            reset();
            setSuccess(true);
            dispatch(fetchUsers());
        }
    }, [userInvite.status, reset, dispatch]);

    return (
        <SideCreate show={show} title={t('users.inviteUser')} toggle={onHide}>
            {success && (
                <div className="text-center">
                    <p className="text-xl text-green-700 font-bold mb-4">
                        {t('users.invite.success')}
                    </p>
                    <div className="flex space-x-6 justify-center">
                        <Button
                            label={t('common.done')}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            }
                            onClick={() => {
                                setSuccess(false);
                                reset();
                                toggle();
                            }}
                            variant="primary"
                        />
                        <Button
                            label={t('users.invite.inviteAnother')}
                            onClick={() => {
                                setSuccess(false);
                                reset();
                            }}
                            variant="info"
                        />
                    </div>
                </div>
            )}
            {!success && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('common.email')}
                            <div className="mt-1">
                                <input
                                    id="email"
                                    type="email"
                                    className={`${
                                        errors.email
                                            ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                            : ''
                                    }`}
                                    autoComplete="email"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <p className="text-red-600 ml-2">
                                        {errors.email.type === 'required' &&
                                            t('signIn.errors.emailRequired')}
                                        {errors.email.type === 'pattern' &&
                                            t('common.errors.invalidEmail')}
                                    </p>
                                )}
                            </div>
                        </label>
                    </div>
                    <div className="flex-none w-full md:flex md:justify-evenly md:space-x-4">
                        <div className="mt-2 w-full md:w-1/2">
                            <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('users.firstName')}
                                <div className="mt-1">
                                    <input
                                        id="firstName"
                                        type="text"
                                        className={`${
                                            errors.firstName
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="firstName"
                                        {...register('firstName', {
                                            required: true,
                                        })}
                                    />
                                    {errors.firstName && (
                                        <p className="text-red-600 ml-2">
                                            {errors.firstName.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div className="mt-2 w-full md:w-1/2">
                            <label
                                htmlFor="lastName"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('users.lastName')}
                                <div className="mt-1">
                                    <input
                                        id="lastName"
                                        type="text"
                                        className={`${
                                            errors.lastName
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="lastName"
                                        {...register('lastName', {
                                            required: true,
                                        })}
                                    />
                                    {errors.lastName && (
                                        <p className="text-red-600 ml-2">
                                            {errors.lastName.type ===
                                                'required' &&
                                                t(
                                                    'common.errors.fieldRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <label
                            htmlFor="role"
                            className="block text-sm font-medium text-gray-700"
                        >
                            {t('users.role')}
                            <select
                                id="role"
                                {...register('role', {
                                    required: true,
                                    value: UserRoles.USER,
                                })}
                            >
                                {Object.values(UserRoles).map((role) => (
                                    <option key={role} value={role}>
                                        {t(`users.roles.${role}`)}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="mt-4">
                        {userInvite.status === 'error' && (
                            <p className="text-red-600 mb-2">
                                {userInvite.error}
                            </p>
                        )}
                        {userInvite.status === 'success' && (
                            <p className="text-green-600 mb-2">
                                {t('common.done')}
                            </p>
                        )}
                        <button
                            disabled={userInvite.status === 'loading'}
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                        >
                            {userInvite.status === 'loading'
                                ? t('wait.message')
                                : t('users.invite.buttonLabel')}
                        </button>
                    </div>
                </form>
            )}
        </SideCreate>
    );
}
