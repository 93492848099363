import React, { SVGProps } from 'react';

export interface IActionButtonProps {
    label: string;
    icon?: string | undefined;
    svgIcon?: SVGProps<SVGElement> | undefined;
    action?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    showLabel?: boolean;
    className?: string;
    iconPosition?: 'left' | 'right';
}

/**
 * Header action item
 * @param label Label of the item
 * @param icon Icon of the item
 * @param path Path of the item
 * @param action Action if action is present the item is a button
 * @param showLabel Show label or not
 * @param className Class name
 * @param svgIcon SVG icon
 *
 */
export default function ActionButton({
    label,
    icon,
    action,
    showLabel,
    className,
    svgIcon,
    iconPosition,
}: IActionButtonProps) {
    if (!showLabel && !icon && !svgIcon) {
        return (
            <div className="bg-red-500 text-white p-4 symlab-action-button__error">
                ActionButton Error: icon or svgIcon is required when showLabel
                is false.
            </div>
        );
    }

    if (!action) {
        return (
            <div className="bg-red-500 text-white p-4 symlab-action-button__error">
                ActionButton Error: action is required.
            </div>
        );
    }

    if (showLabel && icon) {
        return (
            <button
                className={`flex symlab-action-button__item ${className}`}
                type="button"
                onClick={action}
            >
                {iconPosition === 'left' && (
                    <img
                        className="w-6 mr-1 symlab-action-button__item-icon symlab-action-button__item-icon-img symlab-action-button__icon-left"
                        src={icon}
                        alt={label}
                    />
                )}{' '}
                <span className="symlab-action-button__item-label">
                    {label}
                </span>{' '}
                {iconPosition === 'right' && (
                    <img
                        className="w-6 ml-1 symlab-action-button__item-icon symlab-action-button__item-icon-img symlab-action-button__icon-right"
                        src={icon}
                        alt={label}
                    />
                )}
            </button>
        );
    }

    if (showLabel && svgIcon) {
        return (
            <button
                className={`flex symlab-action-button__item ${className}`}
                type="button"
                onClick={action}
            >
                {iconPosition === 'left' && (
                    <div className="symlab-action-button__item-icon symlab-action-button__item-icon-svg symlab-action-button__item-icon-left">
                        {svgIcon}
                    </div>
                )}
                <span
                    className={`${
                        iconPosition === 'left' ? 'ml-1' : 'mr-1'
                    } symlab-action-button__item-label`}
                >
                    {label}
                </span>{' '}
                {iconPosition === 'right' && (
                    <div className="symlab-action-button__item-icon symlab-action-button__item-icon-svg symlab-action-button__item-icon-right">
                        {svgIcon}
                    </div>
                )}
            </button>
        );
    }

    if (icon) {
        return (
            <button
                className={`symlab-action-button__item ${className}`}
                type="button"
                onClick={action}
            >
                <img
                    className="w-6 symlab-action-button__item-icon symlab-action-button__item-icon-img"
                    src={icon}
                    alt={label}
                />
            </button>
        );
    }

    if (svgIcon) {
        return (
            <button className={className} type="button" onClick={action}>
                <div className="symlab-action-button__item-icon symlab-action-button__item-icon-svg">
                    {svgIcon}
                </div>
            </button>
        );
    }

    if (showLabel) {
        return (
            <button className={className} type="button" onClick={action}>
                <div className="symlab-action-button__item-icon symlab-action-button__item-icon-svg">
                    <span className="symlab-action-button__item-label">
                        {label}
                    </span>
                </div>
            </button>
        );
    }

    return (
        <div className="bg-red-500 text-white p-4 symlab-action-button__error">
            ActionButton Error: Unkown error, check parameters.
        </div>
    );
}

ActionButton.defaultProps = {
    icon: undefined,
    action: undefined,
    className: '',
    showLabel: true,
    svgIcon: undefined,
    iconPosition: 'left',
};
