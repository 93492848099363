import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonPortfolioRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPortfolioRelationState {
    data: IPersonPortfolioRelation[];
    status: Status;
    error: string | null;
}

const initialState: PersonPortfolioRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPersonPortfolioRelationships = createAsyncThunk(
    'personPortfolioRelationships/fetchPersonPortfolioRelationships',
    async () => {
        const response = await APIClient.getData(
            '/portfolio/relationship/type/client/?limit=100000'
        );
        return response.data.results;
    }
);

export const personPortfolioRelationshipSlice = createSlice({
    name: 'personPortfolioRelationships',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPersonPortfolioRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                fetchPersonPortfolioRelationships.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                fetchPersonPortfolioRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});
