/* eslint-disable no-param-reassign */
export async function stall(stallTime = 3000) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, stallTime));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateDate = ( splitDate:any, value:any) => {
	if(splitDate.length === 3 && value !== undefined && splitDate[2].length === 2 && splitDate[1].length === 2 && splitDate[0].length === 4){
		return  `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
	}
	return false;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateTypeAttribute = (value: any) => {
	let type =  '';
	switch (value) {
		case 1:
			type = 'Libre'
			break;
		case 2:
			type = 'Colección'
			break;
		case 3:
			type = 'Fecha'
			break;
		case 4:
			type = 'Numérico'
			break;
		default:
			break;
	}
	return type;
}


export const validateType = (key:number) => {
	let val = ''
	switch (key) {
		case 3:
			val = 'date'
			break;
		case 4:
			val = 'number'
			break
		default:
			val = 'text'
			break;
	}
	return val
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dv = (T: any) => {
	let M = 0;
	let S = 1;
	for(;T;T=Math.floor(T/10))
		// eslint-disable-next-line no-plusplus
		S=(S+T%10*(9-M++%6))%11;
	return S?S-1:'k';
	
}

export const validaRut = (rutCompleto: string ) => {

	rutCompleto = rutCompleto.replace("‐","-");
	if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
		return false;
	const tmp 	= rutCompleto.split('-');
	let digv	= tmp[1]; 
	const rut 	= tmp[0];
	// eslint-disable-next-line eqeqeq
	if ( digv == 'K' ) digv = 'k' ;
	
	// eslint-disable-next-line eqeqeq
	return (dv(rut) == digv );
	
}
