import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IUser } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface InviteUserState {
    user: IUser | null;
    status: Status;
    error: string | null;
}

interface IUserInvite {
    email: string;
    attributes: {
        firstName: string;
        lastName: string;
        role: string;
    };
}

const initialState: InviteUserState = {
    user: null,
    status: Status.idle,
    error: null,
};

export const inviteUserAction = createAsyncThunk(
    'users/inviteUser',
    async ({ email, attributes }: IUserInvite) => {
        const response = await APIClient.postData('/users/invite', {
            email,
            attributes,
        });
        return response.data;
    }
);

export const inviteUserSlice = createSlice({
    name: 'inviteUser',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(inviteUserAction.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(inviteUserAction.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched users to the array
                state.user = action.payload;
            })
            .addCase(inviteUserAction.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
