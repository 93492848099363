/* eslint-disable react/jsx-props-no-spreading */
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Column, useTable, useSortBy, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { IEntity } from '../../../../modules/common/interfaces';
import Button from '../../../../components/elements/Buttons/Button';
import Modal from '../../../../components/Modal';
import AutoComplete from '../../../../components/Autocomplete';
import { IPortfolio } from '../../../../modules/portfolios/interfaces/index';
import {
    fetchSinglePerson,
    addClientPortfolioRelation,
} from '../../../../modules/persons/features/singlePersonSlice';

import { RootState, useAppDispatch } from '../../../../store';

import PageHeader from '../../../../components/PageHeader';
import { fetchPersonPortfolioRelationships } from '../../../../modules/person-portfolio-relationships/features/personPortfolioRelationshipSlice';
import { fetchPortfolios } from '../../../../modules/portfolios/features/porfoliosSlice';
import { resetValues } from '../../../../modules/persons-relationships-attributes/features/personRelationshipAttributesValuesSlice';

type FormData = {
    portfolio_to: number;
    relationship_name: string;
};

function AutoCompleteItem({
    item,
    onClick,
}: {
    item: IPortfolio;
    onClick: () => void;
}) {
    return (
        <li>
            <button
                type="button"
                onClick={onClick}
                className="text-left hover:bg-slate-200 flex gap-4 p-4 w-full"
            >
                <div>
                    <h3 className="text-sm font-semibold">
                        {item.reference_id}
                    </h3>
                </div>
            </button>
        </li>
    );
}

export default function PortfolioRelTable({
    data,
    clientId,
}: {
    data: IEntity[];
    clientId: number;
}) {
    const [showAdd, setShowAdd] = useState(false);
    const [addRelationshipState, setAddRelationshipState] = useState('idle');
    const relations = useSelector(
        (state: RootState) => state.personPortfolioRelationships
    );

    const portfolios = useSelector((state: RootState) => state.portfolios);
    const dispatch = useAppDispatch();
    const [selectedValue, setSelectedValue] = useState<string>('');

    useEffect(() => {
        if (relations.status === 'idle') {
            dispatch(fetchPersonPortfolioRelationships());
        }
        
    }, [dispatch, relations.status]);

    useEffect(() => {
        if (portfolios.status === 'idle') {
            dispatch(fetchPortfolios());
        }
    }, [portfolios.status, dispatch]);


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<FormData>();

    const navigate = useNavigate();
 



    const columns = useMemo<Column<IEntity>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
				// eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-explicit-any
				Cell: ({ cell: { value } }: { cell: { value: any} }) =>
				value ?
				<a href={`/portfolio/${value}`} className="underline">{value}</a>
				:
				value
            },
            {
                Header: t('common.entity').toString(),
                accessor: 'entity',
            },
            {
                Header: t('common.relationship').toString(),
                accessor: 'name',
            },
        ],

        []
    );

    const tableInstance = useTable(
        {
            columns,
            data: data || [],
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
        },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = tableInstance;

    const clickSearchItem = (clientTo: number) => {
        const port = portfolios.data.find(
            (portfolio: IPortfolio) => portfolio.id === clientTo
        );

        if (port) {
            setSelectedValue(`${port?.reference_id}`);
            setValue('portfolio_to', port.id);
        }
    };

    const onSubmit = async (dat: FormData) => {
        const attributes = {
            client_from: clientId,
            portfolio_to: dat.portfolio_to,
            relationship_name: dat.relationship_name,
        };

        try {
            setAddRelationshipState('loading');
            await dispatch(
                addClientPortfolioRelation({
                    id: dat.portfolio_to.toString(),
                    attributes,
                })
            ).unwrap();
            await dispatch(fetchSinglePerson(clientId.toString()));
            setAddRelationshipState('success');
        } catch (e) {
            setAddRelationshipState('error');
        }
    };

    return (
        <div className="relative mb-20">
            <PageHeader title={t('relationships.investment')} />
            <div>
                <Button
                    label="Agregar nueva relación"
                    type="button"
                    onClick={() => setShowAdd(!showAdd)}
                />
            </div>
            {data && data.length === 0 && (
                <h3 className="text-lg text-center">No existen relaciones</h3>
            )}
            {data && data?.length > 0 && (
                <>
                    {pageOptions.length > 1 && (
                        <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="overflow-x-auto shadow-md">
                        <table
                            className="table-auto w-full mx-auto"
                            {...getTableProps()}
                        >
                            <thead className="text-white">
                                {
                                    // Loop over the header rows

                                    headerGroups.map((headerGroup) => (
                                        // Apply the header row props

                                        <tr
                                            className="text-left"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {
                                                // Loop over the headers in each row

                                                headerGroup.headers.map(
                                                    (column) => (
                                                        // Apply the header cell props

                                                        <th
                                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                minWidth: '2%',
                                                            }}
                                                        >
                                                            <div className="flex justify-between">
                                                                {
                                                                    // Render the header

                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                }
                                                                {column.canSort ? (
                                                                    <span
                                                                        className={`${
                                                                            column.isSorted &&
                                                                            'text-sym-primary-500'
                                                                        }`}
                                                                    >
                                                                        {column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M19 9l-7 7-7-7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                        {!column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M5 15l7-7 7 7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                    )
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    // Loop over the table rows

                                    page.map((row) => {
                                        // Prepare the row for display

                                        prepareRow(row);

                                        return (
                                            // Apply the row props

                                            <tr
                                                className="odd:bg-slate-200 hover:bg-slate-300"
                                                {...row.getRowProps()}
                                                onClick={() => {
													dispatch(resetValues())
													navigate(`/portfolio-person-relationship-attributes-values/${row.original.idRelationship}/${clientId}/`)
                                                }}
                                            >
                                                {
                                                    // Loop over the rows cells

                                                    row.cells.map((cell) => (
                                                        // Apply the cell props

                                                        <td
                                                            className="py-4 px-2 cursor-pointer"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents

                                                                cell.render(
                                                                    'Cell'
                                                                )
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {pageOptions.length > 1 && (
                        <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                            <div className="flex space-x-4 items-center">
                                <button
                                    type="button"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    {'<<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    {'<'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    {'>'}
                                </button>{' '}
                                <button
                                    type="button"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {'>>'}
                                </button>{' '}
                                <span>
                                    Página{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{' '}
                                </span>
                                <span>
                                    | Ir la página:{' '}
                                    <input
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={(e) => {
                                            const newPage = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(newPage);
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>{' '}
                            </div>
                            <div>
                                <select
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[50, 100, 200, 300].map((pageS) => (
                                        <option key={pageS} value={pageS}>
                                            Mostrar {pageS}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}
                </>
            )}

            {showAdd && (
                <Modal
                    setShowModal={setShowAdd}
                    onClose={() => {
                        reset();
                        setSelectedValue('');
                        setShowAdd(false);
                    }}
                    className="h-[600px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            {t('person-person-relationship.add.addNew')}
                        </h3>
                        {/* START Business Selection */}
                        <div>
                            <div className="w-full mt-4">
                                <div className="block text-sm font-medium text-gray-700 -mb-2">
                                    portfolios *
                                </div>
                                <AutoComplete
                                    getItems={({
                                        query,
                                    }: {
                                        query: string;
                                    }) => {
                                        setSelectedValue('');
                                        const items = portfolios.data?.filter(
                                            (portfolio) =>
                                                portfolio?.reference_id
                                                    ?.toLowerCase()
                                                    .includes(
                                                        query.toLowerCase()
                                                    )
                                        );
                                        if (!items) {
                                            return [];
                                        }
                                        return items;
                                    }}
                                    sourceId="client_to"
                                    onClick={clickSearchItem}
                                    Item={AutoCompleteItem}
                                    value={selectedValue}
                                    placeholder="Buscar Portfolios"
                                />
                            </div>
                            {/* END Business Selection */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="w-full -mt-4 mb-4">
                                    <label
                                        htmlFor="relationship_name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.relationship')} *
                                        <div className="mt-1">
                                            <select
                                                id="relationship_name"
                                                className={`${
                                                    errors.relationship_name
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register(
                                                    'relationship_name',
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                            >
                                                <option value="">
                                                    {t(
                                                        'person-person-relationship.add.selectARelationship'
                                                    )}
                                                </option>
                                                {relations.status ===
                                                    'success' &&
                                                    relations.data.length > 0 &&
                                                    relations.data.map(
                                                        (attribute) => (
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.name
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.relationship_name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                <div className="mt-4">
                                    {addRelationshipState === 'error' && (
                                        <p className="text-red-600 mb-2">
                                            {t('attributes.create.error')}
                                        </p>
                                    )}
                                    {addRelationshipState === 'success' && (
                                        <p className="text-green-600 mb-2">
                                            {t('attributes.create.success')}
                                        </p>
                                    )}
                                    <button
                                        disabled={
                                            addRelationshipState === 'loading'
                                        }
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        {addRelationshipState === 'loading'
                                            ? t('wait.message')
                                            : t('common.save')}
                                    </button>
                                    <Button
                                        type="button"
                                        variant="link"
                                        className="w-full mt-10"
                                        label={t('common.cancel')}
                                        onClick={() => {
                                            setShowAdd(false);
                                            setSelectedValue('');
                                            reset();
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
