/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal';
import { RootState } from '../../../../store';
import {
    resetSingleEntityPerson,
} from '../../../../modules/persons/features/personEntitySlice';
import { validaRut } from '../../../../utils/helpers';

import LoadingAnimated from '../../../../components/elements/Loading/index';
import { updateCompany } from '../../../../modules/business/features/businessEntity';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: string;
    rut: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function UpdateEntity({
    setShowUpdate,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowUpdate: any;
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const company = useSelector((state: RootState) => state.company);
 
    const update = useSelector((state: RootState) => state.businessEntity);

    const { id } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>();



    useEffect(() => {
        if (company.status === 'success') {

            if (company.data) {

                setValue('name', company.data.name);
                setValue('rut', company.data.rut);
            }
        }
    }, [dispatch, company.data, company.status, setValue]);

    const onSubmit = (data: FormData) => {
        const payload = {
            id: Number(id),
            name: data.name,
            rut: data.rut,
        };
        dispatch(updateCompany(payload));
    };

    const [message, setMessage] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');

    useEffect(() => {
        if (update.error) {
            setMessage('Error al actualizar entidad');
            dispatch(resetSingleEntityPerson());
        }
    }, [dispatch, update.error]);

    useEffect(() => {
        if (!update.error && update.status === 'success') {
            setMessage('');
            setMessageSuccess('Entidad Actualizada');
            dispatch(resetSingleEntityPerson());
        }
    }, [dispatch, update.error, update.status]);

    return (
        <>
            {company.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}
            {company.status === 'success' && (
                <Modal
                    setShowModal={setShowUpdate}
                    onClose={() => setShowUpdate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            Actualizar compañía
                        </h3>
                        <div>
                            {/* onSubmit={}handleSubmit(onSubmit) */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nombre *
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('name', {
                                                    required: true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {errors.name && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>

                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Rut *
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                {...register('rut', {
                                                    required: true,
                                                    validate: (e) =>
                                                        validaRut(e) === true,
                                                })}
                                            />
                                        </div>
                                    </label>
                                    {errors.rut &&
                                        errors.rut?.type !== 'validate' && (
                                            <p className="text-red-600 ml-2">
                                                {t(
                                                    'common.errors.fieldRequired'
                                                )}
                                            </p>
                                        )}
                                    {errors.rut &&
                                        errors.rut?.type === 'validate' && (
                                            <p className="text-red-600 ml-2">
                                                Formato incorrecto debe ser
                                                XXXXXXXX-X
                                            </p>
                                        )}
                                </div>
                            
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                >
                                    {update.status === 'loading'
                                        ? t('wait.message')
                                        : t('common.update')}
                                </button>
                            </form>
                        </div>
                        {message !== '' && (
                            <p className="text-red-600 text-center mt-2">
                                {message}
                            </p>
                        )}
                        {messageSuccess !== '' && (
                            <p className="text-green-600 text-center mt-2">
                                {messageSuccess}
                            </p>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
}
