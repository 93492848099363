

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { ICompanyRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface CompanyRelationshipAttributeValueState {
    data: ICompanyRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: CompanyRelationshipAttributeValueState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchCompanyRelationshipAttributesValues = createAsyncThunk(
    'companyAttributesValues/fetchCompanyAttributesValues',
    async (id: string) => {
        const response = await APIClient.getData(
            `/company/relationship/id/${id}/detail/${id}?limit=100000`
        );
        return response.data.results;
    }
);
export const companiesRelationshipAttributesValuesSlice = createSlice({
    name: 'companyAttributesValues',
    initialState,
    reducers: {

		resetValuesCompaniesRel: (state) => {
			state.data = [];
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
            .addCase(fetchCompanyRelationshipAttributesValues.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchCompanyRelationshipAttributesValues.fulfilled, (state, action) => { 
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = action.payload;
            })
            .addCase(fetchCompanyRelationshipAttributesValues.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
          
    },
});
export const { resetValuesCompaniesRel } = companiesRelationshipAttributesValuesSlice.actions;
