/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal';
import { RootState } from '../../../../store';
import { deleteCompany, resetEntityBusines } from '../../../../modules/business/features/businessEntity';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DeleteEntity({
    setShowDelete,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowDelete: any;
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const businessDelete = useSelector((state: RootState) => state.businessEntity);

    const { id } = useParams();

	const onSubmit = () => {
        const payload = {
            id: Number(id),
        };
        dispatch(deleteCompany(payload));
    };

    const [message, setMessage] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');

    useEffect(() => {
        if (businessDelete.error) {
            setMessage('Error al eliminar entidad');
            dispatch(resetEntityBusines());
        }
    }, [dispatch, businessDelete.error]);

	const  navigate = useNavigate();

    useEffect(() => {
        if (!businessDelete.error && businessDelete.status === 'success') {
            setMessage('');
            setMessageSuccess('Entidad Eliminada');
            dispatch(resetEntityBusines());
			setTimeout(() => {
				navigate('/')
			}, 2000);
        }
    }, [dispatch, navigate, businessDelete.error, businessDelete.status]);

    return (
        <Modal
            setShowModal={setShowDelete}
            onClose={() => setShowDelete(false)}
            className="h-[500px]"
        >
            <div className="px-4">
                <h3 className="text-center text-2xl font-bold mb-10">
                    ¿Estas seguro?
                </h3>
                <div className="flex text-center">
                    <button
                        type="button"
                        onClick={() => onSubmit()}
                        className="w-full ml-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none "
                    >
                        {businessDelete.status === 'loading'
                            ? t('wait.message')
                            : 'si'}
                    </button>
                    <button
                        type="button"
                        onClick={() => setShowDelete(false)}
                        className="w-full ml-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-stone-500  hover:bg-stone-600 focus:outline-none  "
                    >
                        {businessDelete.status === 'loading'
                            ? t('wait.message')
                            : 'No'}
                    </button>
                </div>
                {message !== '' && (
                    <p className="text-red-600 text-center mt-2">{message}</p>
                )}
                {messageSuccess !== '' && (
                    <p className="text-green-600 text-center mt-2">
                        {messageSuccess}
                    </p>
                )}
            </div>
        </Modal>
    );
}
