/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { SVGProps } from 'react';

export interface IItems {
    label: string;
    icon?: SVGProps<SVGElement> | undefined;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    subItems?: IItems[];
    route: string;
    goTo?: (path: string) => void;
    currentPath?: string | undefined;
    activeRoute?: string | undefined;
    noLink?: boolean;
}

function SubItem({
    label,
    icon,
    className,
    labelClassName,
    disabled = false,
    route,
    goTo = () => null,
    currentPath,
}: IItems) {
    const active = currentPath === route;
    return (
        <li
            role="menuitem"
            className={`symlab-sidebar__subitem mt-1 first:mt-0  border-l-0 hover:border-r-4 hover:border-r-slate-700   ${
                active
                    ? 'border-r-4 bg-slate-200 border-r-slate-700 text-sym-bg-blue'
                    : 'border-l-slate-300 '
            } ${className}`}
        >
            <button
                role="link"
                type="button"
                className={`symlab-sidebar__subitem-button disabled:cursor-not-allowed w-full flex pr-4 py-2  ${
                    icon ? 'pl-10 ml-1' : 'pl-4'
                } ${
                    disabled
                        ? 'text-slate-500'
                        : 'hover:bg-slate-200 hover:text-sym-bg-blue'
                }`}
                disabled={disabled}
                onClick={() => {
                    if (!disabled) {
                        goTo(route);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (!disabled) {
                            goTo(route);
                        }
                    }
                }}
            >
                <span className="symlab-sidebar__subitem-icon">{icon}</span>
                <span
                    className={`symlab-sidebar__subitem-label ${
                        icon ? 'ml-2' : 'ml-8'
                    } ${
                        active ? 'font-bold' : 'font-normal'
                    } ${labelClassName}`}
                >
                    {label}
                </span>
            </button>
        </li>
    );
}

export default function ItemMain({
    label,
    icon,
    className,
    labelClassName,
    disabled = false,
    subItems = [],
    route,
    currentPath,
    activeRoute,
    goTo = () => null,
    noLink = false,
}: IItems) {
    const mainRoute = route?.split('/')[1];
    const actR = currentPath?.split('/')[1];
    const active = activeRoute === actR || mainRoute === actR;
    // const subItemActive = subItems.some((item) => currentPath === item.route);

    return (
        <li
            role="menuitem"
            className={`symlab-sidebar__item mt-1 first:mt-0 border-l-4 hover:border-l-slate-100 hover:text-sym-bg-blue hover:bg-slate-200    ${
                active
                    ? 'border-l-slate-100 text-sym-bg-blue'
                    : 'border-l-sym-bg-blue '
            } ${active ? 'bg-slate-300' : ''} ${
                subItems.length > 0 ? 'symlab-sidebar__item_with_subitems' : ''
            } ${className}`}
            aria-haspopup={subItems.length > 0}
            aria-expanded={subItems.length > 0}
        >
            <button
                role="link"
                type="button"
                className={`symlab-sidebar__item-button disabled:cursor-not-allowed w-full flex px-4 py-2  ${
                    disabled ? 'text-slate-500' : 'hover:bg-slate-200 '
                }`}
                disabled={disabled}
                onClick={() => {
                    if (!disabled && !noLink) {
                        goTo(route);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (!disabled && !noLink) {
                            goTo(route);
                        }
                    }
                }}
            >
                <span className="symlab-sidebar__item-icon">{icon}</span>
                <span
                    className={`symlab-sidebar__item-label ${
                        icon ? 'ml-2' : 'ml-8'
                    } ${
                        active ? 'font-bold' : 'font-normal'
                    } ${labelClassName}`}
                >
                    {label}
                </span>
            </button>
            {subItems.length > 0 && (
                <ul role="menu">
                    {subItems.map((item) => (
                        <SubItem
                            key={item.label}
                            label={item.label}
                            icon={item.icon}
                            className={item.className}
                            labelClassName={item.labelClassName}
                            disabled={item.disabled}
                            subItems={item.subItems}
                            route={item.route}
                            currentPath={currentPath}
                            goTo={goTo}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
}
