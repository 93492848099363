import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolio } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioState {
    data: IPortfolio[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPortfolios = createAsyncThunk(
    'portfolios/fetchPortfolios',
    async () => {
        const response = await APIClient.getData('/portfolio/?limit=100000');
        return response.data.results;
    }
);


export const addPortfolioPortfolioRelation = createAsyncThunk(
    'singlePortfolio/addPortfolioPortfolioRelation',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { portfolio_from: number; portfolio_to: number; relationship_name: string  };
    }) => {
        const response = await APIClient.postData(`/portfolio/id/${id}/relationship/portfolio/`, attributes);
        return response.data;
    }
);


export const portfoliosSlice = createSlice({
    name: 'portfolios',
    initialState,
    reducers: {
			resetPortfolio: (state) => {
				state.data = [];
				state.status = Status.idle;
				state.error =  null;
			},
		},
    extraReducers(builder) {
        builder
            .addCase(fetchPortfolios.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(fetchPortfolios.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data = state.data.concat(action.payload);
            })
            .addCase(fetchPortfolios.rejected, (state, action) => {
                state.status = Status.success;
                state.error = action.error.message ?? null;
            })
            .addCase(addPortfolioPortfolioRelation.pending, (state) => {
                state.status = Status.loading;
                // Add any fetched data to the array
            })
            .addCase(addPortfolioPortfolioRelation.fulfilled, (state) => {
                state.status = Status.success;                
            })
            .addCase(addPortfolioPortfolioRelation.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            });
    },
});
export const { resetPortfolio } = portfoliosSlice.actions;
