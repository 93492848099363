import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonRelationshipAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonRelationshipAttributeState {
    data: IPersonRelationshipAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPersonRelationshipAttributes = createAsyncThunk(
    'personClientRelationshipAttributes/fetchRelationshipAttributes',
    async () => {
        const response = await APIClient.getData(
            '/client/relationship/attribute/?limit=100000'
        );
        return response.data.results;
    }
);

export const createPersonRelationshipAttribute = createAsyncThunk(
    'personClientRelationshipAttributes/create',
    async (body: {
        label: string;
        name: string;
        index: number;
        type: number;
        is_unique: boolean;
        parent_attribute_id: number;
        client_relationship_type: number;
    }) => {
        const response = await APIClient.postData(
            '/client/relationship/attribute/',
            body
        );
        return response.data;
    }
);
export const createPersonRelationshipAttributeEnum = createAsyncThunk(
    'personClientRelationshipAttributes/createEnum',
    async (body: {
        name: string;
        // attribute: number;
        client_relationship_attribute: number;
    }) => {
        const response = await APIClient.postData(
            '/client/relationship/enum/',
            body
        );
        return response.data;
    }
);

export const addRelationshiptAttribute = createAsyncThunk(
    'personClientRelationshipAttributes/addRelationshiptAttribute',
    async ({
        id,
        attributes,
    }: {
        id: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes: { name: string; value: any; index: number, idAttribute: number }[];
    }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const neWData: any = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let response: any;
        attributes.forEach(async (element) => {
            neWData.push({
                name: element.name,
                value: element.value,
                index: Number(element.index),
				idAttribute: Number(element.idAttribute)
            });

			response = await APIClient.postData(
				`/client/relationship/id/${id}/detail/${element.idAttribute}/`,
				{
					data: [{
						name: element.name,
						value: element.value,
						index: Number(element.index),
						// idAttribute: Number(element.idAttribute)
					}],
				}
			);
        });

        return response?.data;
    }
);
export const personsRelationshipAttributesSlice = createSlice({
    name: 'personClientRelationshipAttributes',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPersonRelationshipAttributes.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                fetchPersonRelationshipAttributes.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                fetchPersonRelationshipAttributes.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(createPersonRelationshipAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPersonRelationshipAttribute.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                createPersonRelationshipAttribute.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            )
            .addCase(addRelationshiptAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addRelationshiptAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                createPersonRelationshipAttributeEnum.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data.push(action.payload);
                }
            )
            .addCase(
                createPersonRelationshipAttributeEnum.rejected,
                (state, action) => {
                    state.status = Status.error;
                    state.error = action.error.message ?? null;
                }
            );
    },
});
