interface IUnAuthenticatedLayout {
    children: React.ReactNode;
}

export default function UnAuthenticatedLayout({
    children,
}: IUnAuthenticatedLayout) {
    return (
        <div className="min-h-screen bg-sym-background flex flex-col justify-center py-12 px-6 lg:px-8">
            <main className="sm:mx-auto sm:w-full sm:max-w-md">{children}</main>
        </div>
    );
}
