/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPortfolioPersonAttribute } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PortfolioPersonRelationshipAttributeState {
    data: IPortfolioPersonAttribute[];
    status: Status;
    error: string | null;
}

const initialState: PortfolioPersonRelationshipAttributeState = {
    data: [],
    status: Status.idle,
    error: null,
};




export const addPortfolioPersonRelationshiptAttribute = createAsyncThunk(
    'singlePortfolioPerson/addPortfolioPersonRelationshiptAttribute',
    async ({
        id,
        attributes,
    }: {
        id: string;
        attributes: { name: string; value: string, index: number, idAttribute: number}[];
    }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const neWData: any = []
		let response: any;

        attributes.forEach(async element => {
            neWData.push(            {
                name: element.name,
                value: element.value,
                index: Number(element.index )
            })

			response = await APIClient.postData(`/portfolio/relationship/client/id/${id}/detail/${element.idAttribute}/`, {
				data: [{
					name: element.name,
					value: element.value,
					index: Number(element.index),
					// idAttribute: Number(element.idAttribute)
				}],		
						});
			
        });


        return response.data;
    }
);
export const portfolioPersonRelationshipAttributesValueSlice = createSlice({
    name: 'singlePortfolioPerson',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(addPortfolioPersonRelationshiptAttribute.rejected, (state, action) => {
                state.status = Status.error;
                state.error = action.error.message ?? null;
            })
            .addCase(addPortfolioPersonRelationshiptAttribute.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(addPortfolioPersonRelationshiptAttribute.fulfilled, (state, action) => {
                state.status = Status.success;
                // Add any fetched data to the array
                state.data.push(action.payload);
            });
        
    },
});
