/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Column,
    useTable,
    useSortBy,
    usePagination,
    useFilters,
    useGlobalFilter,
} from 'react-table';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import PageHeader from '../../../components/PageHeader';

import { Button } from '../../../components/elements/Buttons';
import LoadingAnimated from '../../../components/elements/Loading';

import TableSearch from '../../../components/Table/TableSearch';
import Modal from '../../../components/Modal';
import EditableCell from '../../../components/Table/EditableCell';
import {  IItemAttributeEnum } from '../../../modules/common/interfaces/index';
// import { fetchportfolioPersonRelationshipAttributes } from '../../../modules/business-attributes/features/portfolioPersonRelationshipAttributesSlice';
// import { fetchAllPersonRelationshipEnum } from '../../../modules/person-relationship-enums/';
import {  resetEntityEnumPortfolioPersonRelationship } from '../../../modules/person-relationship-enums/personRelationshipEntityEnum';
import { fetchAllPortfolioPersonRelationshipEnum } from '../../../modules/portfolio-person-relationship-enums/fetchPortfolioPersonRelationshipEnum';
import { createPortfolioPersonRelationshipAttributeValueEnum, updatePortfolioPersonRelationshipAttributeValueEnum } from '../../../modules/portfolio-person-relationship-enums/portfolioPersonRelationshipEntityEnum';
import { fetchPortfolioPersonAttributes } from '../../../modules/portfolio-persons-attributes/features/portfoliosPersonsAttributeSlice';

type FormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    attribute: number;
};



export default function ListPortfolioPersonRelationshipAttributesEnum() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const portfolioPersonRelationshipEnums = useSelector(
        (state: RootState) => state.allPortfolioRelationshipEnums
    );

    const portfolioPersonRelationshipAttributes = useSelector(
        (state: RootState) => state.PortfoliosPersonsRelationshipsAttributes
    );

    const singlePortfolioPersonEnum = useSelector(
        (state: RootState) => state.portfolioRelationshipEnum
    );



	const [addRequestStatus, setAddRequestStatus] = useState('')


    const [showCreate, setShowCreate] = useState(false);


	useEffect(() => {
	  if(singlePortfolioPersonEnum.status === 'success'){
		dispatch(fetchAllPortfolioPersonRelationshipEnum());
		dispatch(resetEntityEnumPortfolioPersonRelationship())
		setAddRequestStatus('success')
	}

	}, [dispatch, singlePortfolioPersonEnum.status])
	
    const {
        register,
        handleSubmit,
		reset,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (portfolioPersonRelationshipEnums.status === 'idle') {
            dispatch(fetchAllPortfolioPersonRelationshipEnum());
        }
    }, [portfolioPersonRelationshipEnums.status, dispatch]);

    useEffect(() => {
        if (portfolioPersonRelationshipAttributes.status === 'idle') {
            dispatch(fetchPortfolioPersonAttributes());
        }
    }, [portfolioPersonRelationshipAttributes.status, dispatch]);




	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [attributes, setAttributes] = useState<any[]>([]);

	const updateMyData = (
        rowIndex: string | number,
        columnId: number,
        original: IItemAttributeEnum,
		value: string,
    ) => {
			
		if (original.value === value) {
			return;
		}
		
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const upData: any = {
			id: original.id,
			name: original.name,
			value,
			attribute: original.attribute,
		};

		const exist = attributes.findIndex((item) => item.name === upData.name && item.attribute === upData.attribute && value === upData.value );

        if (exist !== -1) {
            attributes[exist] = upData;
            setAttributes([...attributes]);
            return;
        }

        setAttributes([...attributes, upData]);
    };


	// eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: t('common.id').toString(),
                accessor: 'id',
            },
            {
                Header: 'Valor',
                accessor: 'name',
				Cell: EditableCell,

            },
            {
                Header: 'Atributo',
                accessor: 'attribute_name',
            },
  
        ],

        [t]
    );

    const tableInstance = useTable(
        {
            columns,
            data: portfolioPersonRelationshipEnums.data,
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            initialState: {
                pageSize: 100,
                pageIndex: 0,
            },
			updateMyData,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canNextPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    const onSubmit = async (data: FormData) => {	
		try{

			dispatch(
				createPortfolioPersonRelationshipAttributeValueEnum({
					name: data.value,
					portfolio_client_relationship_attribute: data.attribute,
				})
			);
	
			reset();
			setShowCreate(false);
		} catch{
			setAddRequestStatus('error')
		}

    };


	const update = async () => {

		attributes.forEach(attribute => {
			
			dispatch(
				updatePortfolioPersonRelationshipAttributeValueEnum({
					id:attribute.id,
					name: attribute.value,
				})
			)
		});
		setAttributes([]);
		reset();
			
   
    };
    return (
        <div className="relative mb-20">
            <Button
                className="absolute right-0 top-0 z-10"
                label='Crear valor enum'
                type="button"
                onClick={() => setShowCreate(!showCreate)}
            />

		<Button
                className="mr-2 absolute right-40 top-0 z-60"
                label='Actuallizar enum'
                type="button"
                onClick={() => update()}
            />

            <PageHeader title="Enums Relación portfolio persona natural" />

            <div>
                <TableSearch
                    title={t('common.search').toString()}
                    placeholder={t('common.search').toString()}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={(filter: string) =>
                        setGlobalFilter(filter)
                    }
                />
            </div>

            {portfolioPersonRelationshipEnums.status === 'loading' && (
                <LoadingAnimated message={t('common.loading')} />
            )}

            {portfolioPersonRelationshipEnums.status === 'success' && portfolioPersonRelationshipEnums.data.length > 0 && (
                <>
                    <div className="pagination flex space-x-6 mt-4 mb-4 justify-end">
                        <div className="flex space-x-4 items-center">
                            <button
                                type="button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Ir la página:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const newPage = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(newPage);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[50, 100, 200, 300].map((pageS) => (
                                    <option key={pageS} value={pageS}>
                                        Mostrar {pageS}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="overflow-x-auto shadow-md">
                        <table
                            className="table-auto w-full mx-auto"
                            {...getTableProps()}
                        >
                            <thead className="text-white">
                                {
                                    // Loop over the header rows

                                    headerGroups.map((headerGroup) => (
                                        // Apply the header row props

                                        <tr
                                            className="text-left"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {
                                                // Loop over the headers in each row

                                                headerGroup.headers.map(
                                                    (column) => (
                                                        // Apply the header cell props

                                                        <th
                                                            className="px-2 py-2 bg-slate-500 border-2 border-white rounded-lg"
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            style={{
                                                                minWidth: '2%',
                                                            }}
                                                        >
                                                            <div className="flex justify-between">
                                                                {
                                                                    // Render the header

                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                }
                                                                {column.canSort ? (
                                                                    <span
                                                                        className={`${
                                                                            column.isSorted &&
                                                                            'text-sym-primary-500'
                                                                        }`}
                                                                    >
                                                                        {column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M19 9l-7 7-7-7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                        {!column.isSortedDesc && (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="h-6 w-6"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M5 15l7-7 7 7"
                                                                                />
                                                                            </svg>
                                                                        )}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                    )
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    // Loop over the table rows

                                    page.map((row) => {
                                        // Prepare the row for display

                                        prepareRow(row);

                                        return (
                                            // Apply the row props

                                            <tr
                                                className="odd:bg-slate-200 hover:bg-slate-300"
                                                {...row.getRowProps()}
                                            >
                                                {
                                                    // Loop over the rows cells

                                                    row.cells.map((cell) => (
                                                        // Apply the cell props

                                                        <td
                                                            className="py-4 px-2 cursor-pointer"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents

                                                                cell.render(
                                                                    'Cell'
                                                                )
                                                            }
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination flex space-x-6 mt-4 mb-12 justify-end">
                        <div className="flex space-x-4 items-center">
                            <button
                                type="button"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                type="button"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                            <span>
                                Página{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Ir la página:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const newPage = e.target.value
                                            ? Number(e.target.value) - 1
                                            : 0;
                                        gotoPage(newPage);
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                        </div>
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[50, 100, 200, 300].map((pageS) => (
                                    <option key={pageS} value={pageS}>
                                        Mostrar {pageS}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </>
            )}

            {showCreate && (
                <Modal
                    setShowModal={setShowCreate}
                    onClose={() => setShowCreate(false)}
                    className="h-[500px]"
                >
                    <div className="px-4">
                        <h3 className="text-center text-2xl font-bold mb-10">
                            Crear valor enum
                        </h3>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label
                                        htmlFor="value"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('common.value')} *
                                        <div className="mt-1">
                                            <input
                                                id="value"
                                                type="text"
                                                className={`${
                                                    errors.value
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register('value', {
                                                    required: true,
                                                })}
                                            />
                                            {errors.value && (
                                                <p className="text-red-600 ml-2">
                                                    {errors.value.type ===
                                                        'required' &&
                                                        t(
                                                            'common.errors.fieldRequired'
                                                        )}
                                                </p>
                                            )}
                                        </div>
                                    </label>
                                </div>
                                {/* START Attributes Selection */}
                                <div className="w-full mt-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        {t('attributes.title')} *
                                        <div className="mt-1">
                                            <select
                                                id="name"
                                                className={`${
                                                    errors.value
                                                        ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                        : ''
                                                }`}
                                                {...register('attribute', {
                                                    required: true,
                                                })}
                                            >
                                                <option value="">
                                                    {t(
                                                        'attributes.create.selectAnAttribute'
                                                    )}
                                                </option>
                                                {portfolioPersonRelationshipAttributes.status ===
                                                    'success' &&
                                                    portfolioPersonRelationshipAttributes.data
                                                        .length > 0 &&
														portfolioPersonRelationshipAttributes.data.map(
														
                                                        (attribute) => (
															attribute.type === 2 &&
                                                            <option
                                                                key={
                                                                    attribute.id
                                                                }
                                                                value={
                                                                    attribute.id
                                                                }
                                                            >
                                                                {attribute.name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                    </label>
                                    {errors.value && (
                                        <p className="text-red-600 ml-2">
                                            {t('common.errors.fieldRequired')}
                                        </p>
                                    )}
                                </div>
                                {/* END Attributes Selection */}

                                <div className="mt-4">
									{addRequestStatus === 'error' && (
                                                <p className="text-red-600 mb-2">
                                                    {t(
                                                        'attributes.create.error'
                                                    )}
                                                </p>
                                            )}
                                            {addRequestStatus === 'success' && (
                                                <p className="text-green-600 mb-2">
                                                    {t(
                                                        'attributes.create.success'
                                                    )}
                                                </p>
                                            )}
                                    <button
										disabled={
											singlePortfolioPersonEnum.status === 'loading'
										}
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                                    >
                                        Guardar
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
