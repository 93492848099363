import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IBusiness } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface UsersState {
    data: IBusiness | null;
    status: Status;
    error: string | null;
}

const initialState: UsersState = {
    data: null,
    status: Status.idle,
    error: null,
};


export const getCompany = createAsyncThunk(
    'singleCompany/getCompany',
    async (id:number) => {
        const response = await APIClient.getData(`/company/id/${id}`);
        return response.data;
    }
);

export const getCompanyEntitySlice = createSlice({
    name: 'companyEntity',
    initialState,
    reducers: {
		resetgetCompany: (state) => {
			state.data = null;
			state.status = Status.idle;
			state.error =  null;
		},
	},
    extraReducers(builder) {
        builder
		.addCase(getCompany.pending, (state) => {
			state.status = Status.loading;
		})
		.addCase(getCompany.fulfilled, (state, action) => {
			state.status = Status.success;
			// Add any fetched data to the array
			state.data = action.payload
		})
		.addCase(getCompany.rejected, (state, action) => {
			state.status = Status.success;
			state.error = action.error.message ?? null;
		})
    },
});
export const { resetgetCompany } = getCompanyEntitySlice.actions;