import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIClient from '../../../services/api/client';
import { IPersonPersonRelation } from '../interfaces';

enum Status {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export interface PersonPersonRelationState {
    data: IPersonPersonRelation[];
    status: Status;
    error: string | null;
}

const initialState: PersonPersonRelationState = {
    data: [],
    status: Status.idle,
    error: null,
};

export const fetchPersonPersonRelationships = createAsyncThunk(
    'personPersonRelationships/fetchPersonPersonRelationships',
    async () => {
        const response = await APIClient.getData(
            '/client/relationship/?limit=100000'
        );
        return response.data.results;
    }
);

export const personPersonRelationshipSlice = createSlice({
    name: 'personPersonRelationships',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPersonPersonRelationships.pending, (state) => {
                state.status = Status.loading;
            })
            .addCase(
                fetchPersonPersonRelationships.fulfilled,
                (state, action) => {
                    state.status = Status.success;
                    // Add any fetched data to the array
                    state.data = state.data.concat(action.payload);
                }
            )
            .addCase(
                fetchPersonPersonRelationships.rejected,
                (state, action) => {
                    state.status = Status.success;
                    state.error = action.error.message ?? null;
                }
            );
    },
});
