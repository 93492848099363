/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useAuth from '../../context/useAuth';

type FormData = {
    email: string;
    password: string;
};

export default function SignIn() {
    const { login, loading, error } = useAuth();

    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const [signInState, setSignInState] = useState<{
        loading: boolean;
        error: {
            code?: string;
            message?: string;
        } | null;
    }>({
        loading: false,
        error: null,
    });

    const onSubmit = (data: FormData) => {
        setSignInState({ ...signInState, loading: true });
        login(data.email, data.password);
        setSignInState({ ...signInState, loading: false });
    };

    const handleSignInError = (code: string) => {
        if (code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user

            // Let's try to resend the code
            console.log('User is not confirmed');
            return t('signIn.errors.UserNotConfirmedException');
            // authService.resendSignUp(user.email).then(data => {
            //     console.log('Code resent, user needs to check his email');
            // }).catch(err => {
            //     console.log('Error resending the code', err);
            // });
        }

        if (code === 'PasswordResetRequiredException') {
            // The error happens when the user tries to sign in using an old/reset password
            // In this case you need to call forgotPassword to reset the password

            // Let's try to reset the password
            console.log('User needs to reset password');
            return t('signIn.errors.PasswordResetRequiredException');
            // authService.forgotPassword(user.email).then(data => {
            //     console.log('Code sent to user via email');
            // }).catch(err => {
            //     console.log('Error resetting password', err);
            // });
        }
        if (code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            console.log('User needs to check his password');
            return t('signIn.errors.NotAuthorizedException');
        }

        if (code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            console.log('User does not exist');
            return t('signIn.errors.UserNotFoundException');
        }

        if (code === 'TemporaryPasswordExpired') {
            // The error happens when the user password is expired
            console.log('User password is expired');
            return t('signIn.errors.TemporaryPasswordExpired');
        }

        if (code === 'NewPasswordRequired') {
            // The error happens when the user password is expired
            console.log('Temporary Password');
            return t('signIn.errors.NewPasswordRequired');
        }

        console.log('Unknown error', code);
        return t('signIn.errors.UnknownError');
    };

    return (
        <div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto"
                    src="/images/logo.svg"
                    alt="MBI Logo"
                    width="300"
                />
                <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-300">
                    {t('signIn.title')}
                </h1>
                <p className="mt-2 text-center text-sm text-white max-w">
                    {t('signIn.invitationCode')}
                    <Link
                        to="/signup"
                        className="font-medium text-sym-primary-600 hover:text-sym-primary-500 focus:outline-none focus:ring-2 focus:ring-sym-primary-500 ml-2"
                    >
                        {t('signIn.signUp')}
                    </Link>
                </p>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
                    <form
                        className="mb-0 space-y-6"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.email')}
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        type="email"
                                        className={`${
                                            errors.email
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        autoComplete="email"
                                        {...register('email', {
                                            required: true,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message:
                                                    'Invalid email address',
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <p className="text-red-600 ml-2">
                                            {errors.email.type === 'required' &&
                                                t(
                                                    'signIn.errors.emailRequired'
                                                )}
                                            {errors.email.type === 'pattern' &&
                                                t(
                                                    'signIn.errors.emailRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>

                        <div>
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('common.password')}
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        type="password"
                                        autoComplete="current-password"
                                        className={`${
                                            errors.password
                                                ? 'border-red-300 rounded-lg shadow-sm focus:border-red-500 focus:ring-red-500'
                                                : ''
                                        }`}
                                        {...register('password', {
                                            required: true,
                                        })}
                                    />
                                    {errors.password && (
                                        <p className="text-red-600">
                                            {errors.password.type ===
                                                'required' &&
                                                t(
                                                    'signIn.errors.passwordRequired'
                                                )}
                                        </p>
                                    )}
                                </div>
                            </label>
                        </div>
                        <div>
                            {error && (
                                <p className="text-red-600 mb-2">
                                    {handleSignInError(error)}
                                </p>
                            )}
                            <button
                                disabled={signInState.loading || loading}
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sym-primary-400 hover:bg-sym-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sym-primary-500"
                            >
                                {loading
                                    ? t('wait.message')
                                    : t('signIn.signIn')}
                            </button>
                        </div>
                    </form>
                    <p className="text-slate-500 text-right mt-6">
                        <Link to="/forgot">{t('signIn.forgot')}</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
